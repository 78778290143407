import Analytics from '@hh.ru/analytics-js';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import VacancyConversionInfo from 'src/components/VacancyConversionInfo';
import translation from 'src/components/translation';
import useAnalyticsEventShownOnce from 'src/hooks/useAnalyticsEventShownOnce';
import { useSelector } from 'src/hooks/useSelector';

import BannerWrapper from 'src/components/VacancyImportantBanners/BannerWrapper';

interface ConversionBannerProps {
    vacancyId: number;
}

const ConversionBanner: TranslatedComponent<ConversionBannerProps> = ({ vacancyId }) => {
    const conversion = useSelector(({ vacancyAnalyticsConversion }) => vacancyAnalyticsConversion);
    const bannerRef = useAnalyticsEventShownOnce({
        name: 'important_banner_conversion',
        vacancyId,
    });

    if (!conversion) {
        return null;
    }

    return (
        <SPALink
            to={`/employer/statistics/vacancy_conversion?vacancyId=${vacancyId}`}
            target="_blank"
            onClick={() => {
                Analytics.sendHHEventButtonClick('important_banner_conversion_button', {
                    vacancyId,
                });
            }}
        >
            <BannerWrapper dataQa="vacancy-important-banner-conversion">
                <div ref={bannerRef}>
                    <VacancyConversionInfo conversion={conversion} />
                </div>
            </BannerWrapper>
        </SPALink>
    );
};

export default translation(ConversionBanner);
