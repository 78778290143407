import { FC } from 'react';
import { Field } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';

import { Input, Link, VSpacingContainer } from '@hh.ru/magritte-ui';
import { PlusOutlinedSize16 } from '@hh.ru/magritte-ui/icon';

import { MAX_QUESTIONS_AMOUNT } from 'src/components/AIAssistant/constants';
import { Question } from 'src/components/AIAssistant/types';

const FieldQuestions: FC = () => {
    const { fields } = useFieldArray<Question>('questions', { subscription: { length: true } });

    const isQuestionsLengthMax = (fields.length ?? 0) >= MAX_QUESTIONS_AMOUNT;

    const handleAddQuestion = () => {
        if (isQuestionsLengthMax) {
            return;
        }
        fields.push('');
    };

    return (
        <VSpacingContainer default={12}>
            <VSpacingContainer default={12}>
                {fields.map((name, index) => (
                    <Field<Question>
                        key={name}
                        name={name}
                        subscription={{ value: true }}
                        parse={(value) => value ?? ''}
                    >
                        {({ input }) => (
                            <Input
                                {...input}
                                data-qa={`hr-ai-assistant__modal-field-question-${index + 1}`}
                                placeholder="Например, спросите про опыт или занятость"
                            />
                        )}
                    </Field>
                ))}
            </VSpacingContainer>
            {!isQuestionsLengthMax && (
                <Link
                    href="/"
                    Element="button"
                    typography="label-3-regular"
                    data-qa="hr-ai-assistant__modal-button-add-question"
                    iconLeft={<PlusOutlinedSize16 />}
                    onClick={handleAddQuestion}
                >
                    Добавить вопрос
                </Link>
            )}
        </VSpacingContainer>
    );
};

export default FieldQuestions;
