import { useSelector } from 'src/hooks/useSelector';
import { AuctionCampaignStatus, CampaignCreationStatus } from 'src/models/auctionData';
import { VacancySearchItem } from 'src/types/search/vacancy/card';

const useVacancySearchSnippet = (): VacancySearchItem | null => {
    const vacancySearchSnippet = useSelector((state) => state.vacancySearchSnippet);
    const campaignCreationStatus = useSelector((state) => state.auctionData?.campaignCreationStatus);
    const campaignStatus = useSelector((state) => state.auctionData?.currentVacancyAuctionCampaign?.campaignStatus);

    if (!vacancySearchSnippet) {
        return null;
    }

    const isAdv =
        campaignCreationStatus === CampaignCreationStatus.CampaignCreated &&
        campaignStatus === AuctionCampaignStatus.Active;

    return {
        ...vacancySearchSnippet,
        '@isAdv': campaignCreationStatus ? Boolean(isAdv) : vacancySearchSnippet['@isAdv'],
    };
};

export default useVacancySearchSnippet;
