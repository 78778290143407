import { useCallback } from 'react';
import { shallowEqual } from 'react-redux';

import { prepareValues } from 'src/components/VacancyCreate/utils';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';
import { VacancyCreateFormValues } from 'src/models/vacancyCreate/vacancyCreate.types';

import useIsEdit from 'src/components/VacancyCreate/hooks/useIsEdit';

type PrepareVacancyType = (vacancyFields: VacancyCreateFormValues) => ReturnType<typeof prepareValues>;

const usePreparedVacancy = (isDelete = false): PrepareVacancyType => {
    const employerInsiders = useSelector(({ employerInsiders }) => employerInsiders, shallowEqual);
    const employerAddresses = useSelector(({ employerAddresses }) => employerAddresses, shallowEqual);
    const employerDepartments = useSelector(({ employerDepartments }) => employerDepartments, shallowEqual);
    const employerTests = useSelector(({ employerTests }) => employerTests, shallowEqual);
    const brandedVacancyTemplates = useSelector(({ brandedVacancyTemplates }) => brandedVacancyTemplates, shallowEqual);
    const brandSnippets = useSelector(({ brandSnippets }) => brandSnippets.templates, shallowEqual);
    const vacancyCreateInitialBody = useSelector(({ vacancyCreateInitialBody }) => vacancyCreateInitialBody);
    const { isEditVacancyOrPendingDraft } = useIsEdit();
    const isBackoffice = useSelector((state) => state.userType === UserType.BackOffice);
    const availablePublicationTypesData = useSelector((state) => state.availablePublicationTypes.data);

    return useCallback(
        (vacancyFields) => {
            const publicationVariant = vacancyFields?.publication?.publicationVacancyProperties?.id;
            let selectedPublicationTypeData = null;
            if (availablePublicationTypesData && publicationVariant) {
                selectedPublicationTypeData = availablePublicationTypesData.publicationTypes[publicationVariant];
            }
            return prepareValues(
                { ...vacancyFields },
                {
                    employerInsiders,
                    employerAddresses,
                    employerDepartments,
                    employerTests,
                    brandedVacancyTemplates,
                    brandSnippets,
                    selectedPublicationTypeData,
                    vacancyCreateInitialBody,
                },
                isDelete,
                isEditVacancyOrPendingDraft,
                isBackoffice
            );
        },
        [
            availablePublicationTypesData,
            employerInsiders,
            employerAddresses,
            employerDepartments,
            employerTests,
            brandedVacancyTemplates,
            brandSnippets,
            vacancyCreateInitialBody,
            isDelete,
            isEditVacancyOrPendingDraft,
            isBackoffice,
        ]
    );
};

export default usePreparedVacancy;
