import { Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';

import { ADD_MODAL, MODAL_VACANCY_ADVICE } from 'src/components/VacancyModals/constants';
import { useSelector } from 'src/hooks/useSelector';
import { MicroFrontends, updateMicroFrontends } from 'src/models/microFrontends';
import { setDataVacancyAdviceModal, VacancyAdviceModalData } from 'src/models/vacancyAdviceModalData';
import fetcher from 'src/utils/fetcher';

const GET_VACANCY_ADVICES_MODAL = '/shards/vacancy/get_vacancy_advices_modal';

declare global {
    interface FetcherGetApi {
        [GET_VACANCY_ADVICES_MODAL]: {
            response: MicroFrontends;
            queryParams: undefined;
        };
    }
}

const useShowAdviceModal = () => {
    const dispatch = useDispatch();
    const microFrontends = useSelector((state) => state.microFrontends);

    const loadProxyComponent = async () => {
        const abortController = new AbortController();
        const response = await fetcher.get(GET_VACANCY_ADVICES_MODAL, {
            signal: abortController.signal,
        });
        dispatch(updateMicroFrontends(response));
    };

    const openModal = async (adviceData: VacancyAdviceModalData, dispatchModal: Dispatch<AnyAction>) => {
        if (!microFrontends?.vacancyAdvicesModal) {
            await loadProxyComponent();
        }
        dispatch(setDataVacancyAdviceModal(adviceData));
        dispatchModal({ type: ADD_MODAL, payload: { modalType: MODAL_VACANCY_ADVICE } });
    };

    return { openModal };
};

export default useShowAdviceModal;
