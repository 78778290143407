import { useState, useRef, ChangeEvent } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Card, Title, Text, VSpacing, TooltipHover, Segmented, Segment, useBreakpoint } from '@hh.ru/magritte-ui';
import { ListShortOutlinedSize24, ListOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import VacancySearchItem from 'src/components/VacancySearchItem';
import Source from 'src/components/VacancySearchItem/types/Source';
import translation from 'src/components/translation';
import { VacancySearchItem as IVacancySearchItem } from 'src/types/search/vacancy/card';

import { SegmentSize } from 'src/pages/VacancyView/components/VacancyMainTab/SearchSnippet/constants';

import styles from './search-snippet.less';

const TrlKeys = {
    title: 'employer.vacancy.snippet.tag.title',
    description: 'employer.vacancy.snippet.card.description',
    tip: {
        short: 'employer.vacancy.snippet.tip.short',
        full: 'employer.vacancy.snippet.tip.full',
    },
};

interface SearchSnippetProps {
    vacancy: IVacancySearchItem;
    onSelectPane: (pane: SegmentSize) => void;
}

const SearchSnippet: TranslatedComponent<SearchSnippetProps> = ({ trls, vacancy, onSelectPane }) => {
    const [activePane, setActivePane] = useState(SegmentSize.Short);
    const shortSegmentSizeRef = useRef(null);
    const fullSegmentSizeRef = useRef(null);
    const { isGtM } = useBreakpoint();

    const onSelect = (event: ChangeEvent<HTMLInputElement>) => {
        const pane = event.target.value as SegmentSize;
        onSelectPane(pane);
        setActivePane(pane);
        Analytics.sendHHEventButtonClick(`vacancy_view_${pane}_segment_snippet_size_select`, {
            vacancyId: vacancy.vacancyId,
        });
    };

    const onShownSnippet = (element: HTMLDivElement) => {
        if (element) {
            Analytics.sendHHEventElementShown(element, {
                name: 'vacancy_view_search_snippet',
                branded: Boolean(vacancy.brandSnippet),
                vacancyId: vacancy.vacancyId,
            });
        }
    };

    const onSnippetClick = () => {
        Analytics.sendHHEventButtonClick('vacancy_view_search_snippet_card', {
            branded: Boolean(vacancy.brandSnippet),
            vacancyId: vacancy.vacancyId,
        });
    };

    const description = (
        <>
            <VSpacing default={12} />
            <Text Element="span" typography="label-2-regular" style="secondary">
                {trls[TrlKeys.description]}
            </Text>
        </>
    );

    return (
        <>
            <Card borderWidth="default" stretched borderRadius={24} padding={24} paddingBottom={8}>
                <div className={styles.searchSnippetBox}>
                    <div>
                        <Title Element="h4" size="small" dataQaTitle="search-snippet-title">
                            {trls[TrlKeys.title]}
                        </Title>
                        {isGtM && description}
                    </div>
                    <div className={styles.searchSnippetControl}>
                        <Segmented mode="icon" selectedValue={activePane} onChange={onSelect}>
                            <Segment
                                value={SegmentSize.Short}
                                icon={ListShortOutlinedSize24}
                                ref={shortSegmentSizeRef}
                                data-qa="segmented-item-short"
                            />
                            <Segment
                                value={SegmentSize.Full}
                                icon={ListOutlinedSize24}
                                ref={fullSegmentSizeRef}
                                data-qa="segmented-item-full"
                            />
                        </Segmented>
                    </div>
                </div>
                {!isGtM && description}
                <VSpacing default={24} />
                <div onClick={onSnippetClick}>
                    <div className={styles.searchSnippetCard} ref={onShownSnippet}>
                        <VacancySearchItem vacancy={vacancy} vacancySource={Source.EmployerVacancy} />
                    </div>
                </div>
            </Card>
            <TooltipHover placement="bottom-center" activatorRef={shortSegmentSizeRef}>
                {trls[TrlKeys.tip.short]}
            </TooltipHover>
            <TooltipHover placement="bottom-center" activatorRef={fullSegmentSizeRef}>
                {trls[TrlKeys.tip.full]}
            </TooltipHover>
        </>
    );
};

export default translation(SearchSnippet);
