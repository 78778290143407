import { FC } from 'react';

import { Button, ButtonStack, Card, Text, useBreakpoint, VSpacing } from '@hh.ru/magritte-ui';
import { AstrostarOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import { BettaIcon } from 'src/components/AIAssistant';

import styles from './styles.less';

interface CardControlProps {
    buttonEnableText?: string;
    buttonEnableLoading?: boolean;
    onEnableClick: () => void;
    onSetUpClick: () => void;
}

const CardControl: FC<CardControlProps> = ({
    buttonEnableText = 'Подключить',
    buttonEnableLoading,
    onEnableClick,
    onSetUpClick,
}) => {
    const { isGtXS } = useBreakpoint();

    return (
        <Card
            stretched
            style="special-secondary"
            borderRadius={24}
            padding={20}
            data-qa="hr-ai-assistant__card-control"
        >
            {isGtXS && <BettaIcon className={styles.iconBetta} />}
            <div className={styles.titleContainer}>
                <AstrostarOutlinedSize24 initialColor="special" data-qa="hr-ai-assistant__card-control-title-icon" />
                <Text typography="paragraph-2-regular" data-qa="hr-ai-assistant__card-control-title">
                    <span className={styles.title}>Подключить AI-ассистента к поиску кандидатов</span>
                </Text>
            </div>
            <VSpacing default={20} />
            <ButtonStack orientation={isGtXS ? 'horizontal' : 'vertical'}>
                <Button
                    mode="primary"
                    style="neutral"
                    size="small"
                    data-qa="hr-ai-assistant__card-control-button-enable"
                    loading={buttonEnableLoading}
                    onClick={onEnableClick}
                >
                    {buttonEnableText}
                </Button>
                <Button
                    mode="tertiary"
                    style="accent"
                    size="small"
                    data-qa="hr-ai-assistant__card-control-button-set-up"
                    onClick={onSetUpClick}
                >
                    Настроить
                </Button>
            </ButtonStack>
        </Card>
    );
};

export default CardControl;
