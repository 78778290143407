import { FC } from 'react';

import { Card, Skeleton } from '@hh.ru/magritte-ui';

import ContainerForMicroFrontend from 'src/components/CodeInjector/ContainerForMicroFrontend';
import { ANCHOR_TO_SECTION } from 'src/components/VacancyImportantBanners/CanvacBanner';
import useAnalyticsEventShownOnce from 'src/hooks/useAnalyticsEventShownOnce';

interface SalaryRangeCardProps {
    vacancyId: number;
}

const SalaryRangeCard: FC<SalaryRangeCardProps> = ({ vacancyId }) => {
    const salaryChartRef = useAnalyticsEventShownOnce({
        name: 'vacancy_view_stat_tab_salary_chart',
        vacancyId,
    });

    return (
        <Card
            id={ANCHOR_TO_SECTION.SalaryRangeCard}
            stretched
            borderWidth="default"
            padding={12}
            borderRadius={24}
            ref={salaryChartRef}
        >
            <ContainerForMicroFrontend
                preloadContent={<Skeleton height={120} borderRadius={24} loading />}
                place={'salaryMarketRangeCard'}
            />
        </Card>
    );
};

export default SalaryRangeCard;
