import { Modal, VSpacing, Text, Action, BottomSheet, NavigationBar } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import Conversion from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { PfpLiquidityStatus } from 'src/models/pfpLiquidityWarning';

import VacanciesList from 'src/components/PfpLiquidityVacanciesTopModal/VacanciesList';

const TrlKeys = {
    title: {
        [PfpLiquidityStatus.ManagerDeprioritized]: 'pfpLiquidityVacanciesTopModal.title.managerDeprioritized',
        [PfpLiquidityStatus.ManagerVacanciesHidden]: 'pfpLiquidityVacanciesTopModal.title.managerVacanciesHidden',
    },
    description: {
        [PfpLiquidityStatus.ManagerDeprioritized]: 'pfpLiquidityVacanciesTopModal.description.managerDeprioritized',
        [PfpLiquidityStatus.ManagerVacanciesHidden]: 'pfpLiquidityVacanciesTopModal.description.managerVacanciesHidden',
    },
    contactsOne: 'contact.1',
    contactsSome: 'contact.2',
    contactsMany: 'contact.5',
};

interface PfpLiquidityVacanciesTopModalProps {
    visible: boolean;
    onClose: VoidFunction;
}

const PfpLiquidityVacanciesTopModal: TranslatedComponent<PfpLiquidityVacanciesTopModalProps> = ({
    trls,
    visible,
    onClose,
}) => {
    const status = useSelector(({ pfpLiquidityWarning }) => pfpLiquidityWarning?.status);
    const vacancies = useSelectorNonNullable(({ pfpLiquidityWarning }) => pfpLiquidityWarning?.vacancies);
    const responsesToBuy = useSelector(({ pfpLiquidityWarning }) => Number(pfpLiquidityWarning?.responsesToBuy));

    if (status !== PfpLiquidityStatus.ManagerDeprioritized && status !== PfpLiquidityStatus.ManagerVacanciesHidden) {
        return null;
    }

    const subTitle = (
        <>
            <VSpacing default={4} m={8} />
            <Text typography="paragraph-1-regular" style="secondary">
                {formatToReactComponent(trls[TrlKeys.description[status]], {
                    '{0}': (
                        <Conversion
                            value={responsesToBuy}
                            one={trls[TrlKeys.contactsOne]}
                            some={trls[TrlKeys.contactsSome]}
                            many={trls[TrlKeys.contactsMany]}
                        />
                    ),
                })}
            </Text>
        </>
    );
    const content = vacancies?.length ? <VacanciesList vacancies={vacancies} /> : null;

    return (
        <>
            <BottomSheet
                visible={visible}
                onClose={onClose}
                height={Number(vacancies?.length) > 3 ? 'full-screen' : 'content'}
                header={<NavigationBar right={<Action icon={CrossOutlinedSize24} onClick={onClose} />} />}
            >
                <Text typography="title-4-semibold">{trls[TrlKeys.title[status]]}</Text>
                {subTitle}
                <VSpacing default={32} />
                {content}
                <VSpacing default={24} />
            </BottomSheet>
            <Modal
                visible={visible}
                onClose={onClose}
                title={
                    <>
                        {trls[TrlKeys.title[status]]}
                        {subTitle}
                    </>
                }
                actions={<Action mode="secondary" icon={CrossOutlinedSize24} onClick={onClose} />}
            >
                {content}
            </Modal>
        </>
    );
};

export default translation(PfpLiquidityVacanciesTopModal);
