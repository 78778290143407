import { useCallback } from 'react';
import { connect } from 'react-redux';

import { AstrostarOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import translation from 'src/components/translation';
import { FromPages } from 'src/utils/constants/fromPages';

import getMenuButtonIconProps from 'src/components/VacancyActions/getMenuButtonIconProps';
import { UPDATE_CREATE_PERMISSION } from 'src/components/VacancyActions/permissions';
import { openBlankRisePage } from 'src/components/VacancyActions/risePageUtils';

const UpgradeAction = ({
    Component,
    trls,
    triggerType,
    permissions,
    additionalAnalyticsParams,
    text,
    actionType,
    displayInOwnColumn,
    vacancyId,
    from,
}) => {
    const triggerName = 'upgrade';
    const iconProps = displayInOwnColumn
        ? undefined
        : getMenuButtonIconProps({
              triggerType,
              triggerName,
              icon: <AstrostarOutlinedSize24 />,
          });

    const handleTrigger = useCallback(() => {
        openBlankRisePage(
            [vacancyId],
            'classificationChanges',
            from === FromPages.MyVacancies,
            from === FromPages.Vacancy ? FromPages.Vacancy : undefined
        );
    }, [from, vacancyId]);

    return (
        <Component
            triggerName={triggerName}
            triggerType={triggerType}
            onTrigger={handleTrigger}
            isPermitted={permissions.includes(UPDATE_CREATE_PERMISSION)}
            additionalAnalyticsParams={additionalAnalyticsParams}
            displayInOwnColumn={displayInOwnColumn}
            actionType={actionType}
            {...iconProps}
        >
            {text || trls[UpgradeAction.trls.improve]}
        </Component>
    );
};

UpgradeAction.trls = {
    info: 'vacancy.update.text',
    upgradeTitle: 'vacancy.upgrade.popup.title',
    fail: 'employer.service.message.fail',
    cancel: 'employer.myVacancyes.move.cancel',
    improve: 'vacancy.tools.improve',
};

export default connect(({ permissions }) => ({
    permissions,
}))(translation(UpgradeAction));
