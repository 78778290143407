import { FC, useId, useMemo } from 'react';

import FormSettings from 'src/components/AIAssistant/components/FormHrSettings';
import { FormValuesHrSettings } from 'src/components/AIAssistant/types';
import {
    adaptSettingsToFormValues,
    adaptFormValuesToSettings,
} from 'src/components/AIAssistant/utils/adaptersHrSettings';
import { useSelector } from 'src/hooks/useSelector';
import { AIAssistantHrSettings } from 'src/models/aiAssistant/aiAssistantHrSettings';

import ModalHrSettings from 'src/components/AIAssistant/components/ModalHrSettings/ModalHrSettings';

interface ModalSettingsProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (settings: AIAssistantHrSettings) => void;
}

const ModalSettings: FC<ModalSettingsProps> = (props) => {
    const { isOpen, onClose, onSubmit } = props;
    const { configurableSettings } = useSelector((state) => state.aiAssistantHR);
    const formId = useId();

    const formValues = useMemo(() => adaptSettingsToFormValues(configurableSettings), [configurableSettings]);

    const handleSubmit = (values: FormValuesHrSettings) => {
        const settings = adaptFormValuesToSettings(values);
        onSubmit(settings);
    };

    return (
        <>
            <ModalHrSettings isOpen={isOpen} formId={formId} onClose={onClose}>
                <FormSettings formId={formId} initialValues={formValues} onSubmit={handleSubmit} />
            </ModalHrSettings>
        </>
    );
};

export default ModalSettings;
