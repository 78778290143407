import { useState } from 'react';

type UseAIAssistantHrSettingsModal = (isOpen?: boolean) => {
    isModalOpen: boolean;
    openModal: () => void;
    closeModal: () => void;
};

const useAIAssistantHrSettingsModal: UseAIAssistantHrSettingsModal = (isOpen = false) => {
    const [isModalOpen, setIsModalOpen] = useState(isOpen);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return {
        isModalOpen,
        openModal,
        closeModal,
    };
};

export default useAIAssistantHrSettingsModal;
