import { Text, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import NumberFormatter from 'bloko/common/numberFormatter';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { COMPARISON_QUALITY_COLOR } from 'src/pages/VacancyView/components/VacancyStatTab/MetricCard';
import getComparisonQuality from 'src/pages/VacancyView/components/VacancyStatTab/MetricCard/getComparisonQuality';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import styles from './competitor-card-heading.less';

const TrlKeys = {
    title: 'employer.vacancy.tab.stat.competitorCard.title',
    subtitleNoCompetitor: 'employer.vacancy.tab.stat.competitorCard.subtitle.noCompetitor',
    description: 'employer.vacancy.tab.stat.competitorCard.description',
    comparison: {
        higher: 'employer.vacancy.tab.stat.competitorCard.comparison.higher',
        lower: 'employer.vacancy.tab.stat.competitorCard.comparison.lower',
    },
};

const CompetitorCardHeading: TranslatedComponent = ({ trls }) => {
    const { data } = useSelector((state) => state.vacancyConversionStatistics);

    const conversionPercent = NumberFormatter.format(String((data?.viewToResponseConversion || 0) * 100), {
        decimalLength: 1,
    });
    const comparisonValue = NumberFormatter.format(String(data?.viewToResponseConversionComparison?.value || 0), {
        decimalLength: 1,
    });
    const comparisonQuality = getComparisonQuality(data?.viewToResponseConversionComparison);
    const textStyle = COMPARISON_QUALITY_COLOR[comparisonQuality];

    const descriptionText = (
        <>
            <VSpacing default={8} />
            <Text Element="span" style="secondary" typography="label-3-regular">
                {trls[TrlKeys.description]}
            </Text>
        </>
    );

    if (comparisonQuality === 'noCalc') {
        return (
            <>
                <Text Element="span" typography="label-2-regular">
                    {trls[TrlKeys.subtitleNoCompetitor]}
                    {NON_BREAKING_SPACE}
                </Text>
                <Text Element="span" typography="label-2-regular" style={textStyle}>
                    {conversionPercent}%
                </Text>
                {descriptionText}
            </>
        );
    }

    const comparisonTextPath = comparisonQuality === 'high' ? TrlKeys.comparison.higher : TrlKeys.comparison.lower;

    return (
        <>
            <div className={styles.competitoCardHeading} data-qa="tab-stat-conversion-comparison-percent">
                <Text Element="p" typography="label-2-regular">
                    {format(trls[TrlKeys.title], {
                        '{0}': conversionPercent,
                    })}
                </Text>
                <Text Element="p" typography="label-2-regular" style={textStyle}>
                    {format(trls[comparisonTextPath], {
                        '{0}': comparisonValue,
                    })}
                </Text>
            </div>
            {descriptionText}
        </>
    );
};

export default translation(CompetitorCardHeading);
