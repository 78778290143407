import { ReactNode, Children, useState } from 'react';

import { Card, FormLabel, Link, VSpacing, VSpacingContainer } from '@hh.ru/magritte-ui';
import { ChevronDownOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';
import AnimatedHeightContainer from 'src/pages/VacancyView/components/VacancyManagersTab/AnimatedHeightContainer';

import styles from './styles.less';

const TrlKeys = {
    moreButtonText: 'employer.vacancy.managers.list.button.more.text',
};

interface ManagerListProps {
    label: string;
    actions?: ReactNode;
    itemsPerStep?: number;
}

const ManagersList: TranslatedComponent<ManagerListProps> = ({ trls, label, actions, itemsPerStep = 3, children }) => {
    const items = Children.toArray(children);
    const [displayedItemsCount, setDisplayedItemsCount] = useState<number>(itemsPerStep);

    const displayedItems = items.slice(0, displayedItemsCount);
    const restItemsCount = items.length - displayedItems.length;
    const nextStepItemsCount = restItemsCount > itemsPerStep ? itemsPerStep : restItemsCount;
    const hasRestItems = restItemsCount > 0;

    const handleAddItems = () => {
        setDisplayedItemsCount((prevCount) => prevCount + itemsPerStep);
    };

    return (
        <div>
            <div className={styles.header}>
                <FormLabel>{label}</FormLabel>
                {!!actions && actions}
            </div>
            <VSpacing default={12} />
            <Card padding={24} borderRadius={24} borderWidth="default" stretched>
                <AnimatedHeightContainer>
                    <VSpacingContainer default={16}>
                        {displayedItems}
                        {hasRestItems && (
                            <Link Element="button" onClick={handleAddItems} iconRight={<ChevronDownOutlinedSize16 />}>
                                {format(trls[TrlKeys.moreButtonText], { '{0}': nextStepItemsCount })}
                            </Link>
                        )}
                    </VSpacingContainer>
                </AnimatedHeightContainer>
            </Card>
        </div>
    );
};

export default translation(ManagersList);
