import { FieldRenderProps } from 'react-final-form';

import { findStaticDataFetcherItemByValue, useStaticDataProvider } from '@hh.ru/magritte-ui';
import { Select } from '@hh.ru/magritte-ui-select';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    title: 'employer.vacancy.VacancyCreateContent.mainInfoSection.hiringPlan.title',
    newHire: 'hiringPlan.type.newHire',
    replacement: 'hiringPlan.type.replacement',
};

const NEW_HIRE = 'NEW_HIRE';
const REPLACEMENT = 'REPLACEMENT';

const PlanType: TranslatedComponent<FieldRenderProps<string>['input']> = ({ trls, value, onChange, name }) => {
    const hiringPlanTypes = [
        { text: trls[TrlKeys.newHire], value: NEW_HIRE },
        { text: trls[TrlKeys.replacement], value: REPLACEMENT },
    ];

    const dataProvider = useStaticDataProvider(hiringPlanTypes);

    return (
        <Select
            type="radio"
            name={name}
            value={findStaticDataFetcherItemByValue(value, hiringPlanTypes)}
            onChange={(newValue) => onChange(newValue.value)}
            dataProvider={dataProvider}
            triggerProps={{
                size: 'medium',
                stretched: true,
                label: trls[TrlKeys.title],
                'data-qa': 'hiring-plan-type',
            }}
            bottomSheetHeight="content"
        />
    );
};

export default translation(PlanType);
