import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Currency from 'src/components/Currency';
import translation from 'src/components/translation';
import { CurrencyType } from 'src/models/currencies.types';
import { EN_DASH, NON_BREAKING_SPACE } from 'src/utils/constants/symbols';
import formatToInt from 'src/utils/formatToInt';

import styles from './salary-info.less';

interface SalaryInfoProps {
    from?: number | null;
    to?: number | null;
    currency?: CurrencyType | null;
    defaultValue?: string;
    startText?: string;
    noWrap?: boolean;
}

const TrlKeys = {
    from: 'vacancy.salaryMarketRangeChart.legend.from',
    to: 'vacancy.salaryMarketRangeChart.legend.to',
};

/**
 * Возвращает данные о ЗП вида: 92 000 - 264 000 ₽, в случае наличия from и to
 * от 92 000 ₽ или до 264 000 ₽, в случае наличия одного из значений - from, to
 *
 * @param from - данные ЗП
 * @param to - данные ЗП
 * @param currency - валюта
 * @param defaultValue - дефолтное значение в случае отсутствия данных from и to
 * @param startText - текст перед выводимым значением
 * @param noWrap - текст в одну строку без переноса
 */
const SalaryInfo: TranslatedComponent<SalaryInfoProps> = ({
    from,
    to,
    currency,
    defaultValue = '',
    startText = '',
    noWrap,
    trls,
}) => {
    if ((!from && !to) || !currency) {
        return (
            <>
                {startText}
                {defaultValue}
            </>
        );
    }

    const currencyText = <Currency value={currency} />;
    const fromText = String(formatToInt(from));
    const toText = String(formatToInt(to));

    const getSalaryText = () => {
        if (from && to) {
            return from === to ? fromText : `${fromText}${NON_BREAKING_SPACE}${EN_DASH}${NON_BREAKING_SPACE}${toText}`;
        }

        const beforeText = from ? trls[TrlKeys.from] : trls[TrlKeys.to];
        const salaryValue = from ? fromText : toText;
        return `${beforeText}${NON_BREAKING_SPACE}${salaryValue}`;
    };

    const salaryText = (
        <>
            {startText}
            {getSalaryText()}
            {NON_BREAKING_SPACE}
            {currencyText}
        </>
    );

    return noWrap ? <span className={styles.salaryInfoNoWrap}>{salaryText}</span> : salaryText;
};

export default translation(SalaryInfo);
