import { ChangeEvent, useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';
import {
    BottomSheet,
    Card,
    Text,
    Button,
    Modal,
    Action,
    NavigationBar,
    BottomSheetFooter,
    useBreakpoint,
    UncontrolledTextArea,
    VSpacing,
    VSpacingContainer,
    ActionBar,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24, CheckCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Notification from 'src/components/Notifications/Notification';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import useAnalyticsEventShownOnce from 'src/hooks/useAnalyticsEventShownOnce';
import useOnOffState from 'src/hooks/useOnOffState';
import { useSelector } from 'src/hooks/useSelector';

import VariantChipChooser from 'src/pages/VacancyView/components/VacancyStatTab/FeedbackCard/VariantChipChooser';
import { Variant } from 'src/pages/VacancyView/components/VacancyStatTab/FeedbackCard/constant';

import styles from './feedback-card.less';

const TrlKeys = {
    title: 'employer.vacancy.tab.stat.feedback.title',
    buttonText: 'employer.vacancy.tab.stat.feedback.buttonText',
    description: 'employer.vacancy.tab.stat.feedback.description',
    buttonCancel: 'employer.vacancy.tab.stat.feedback.buttonCancel',
    buttonSend: 'employer.vacancy.tab.stat.feedback.buttonSend',
    errorText: 'employer.vacancy.tab.stat.feedback.errorText',
    notificationText: 'employer.vacancy.tab.stat.feedback.notificationText',
    placeholder: 'employer.vacancy.tab.stat.feedback.placeholder',
};

interface FeedbackFormValues {
    variant: Variant[];
    text: string;
}

const DEFAULT_FORM_VALUES: FeedbackFormValues = {
    variant: [],
    text: '',
};
const MAX_TEXT_LENGTH = 255;
const DUMMY_VALIDATE = () => true;

const FeedbackCard: TranslatedComponent = ({ trls }) => {
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const [visibleModal, showModal, closeModal] = useOnOffState(false);
    const [visibleError, showError, closeError] = useOnOffState(false);
    const feedbackFormValues = useRef<FeedbackFormValues>({ ...DEFAULT_FORM_VALUES });

    const { addNotification } = useNotification();
    const { isXS } = useBreakpoint();

    const feedbackCardRef = useAnalyticsEventShownOnce({
        name: 'vacancy_view_stat_tab_feedback_card',
        vacancyId,
    });

    const feedbackModalRef = useAnalyticsEventShownOnce({
        name: 'vacancy_view_stat_tab_similar_feedback_modal',
        vacancyId,
    });

    const onShowModal = () => {
        Analytics.sendHHEventButtonClick('vacancy_view_stat_tab_similar_feedback_modal_show', {
            vacancyId,
        });
        showModal();
    };

    const onChangeText = (event: ChangeEvent<HTMLTextAreaElement>) => {
        feedbackFormValues.current.text = event.target.value.trim();
        if (visibleError) {
            closeError();
        }
    };

    const onSelectVariant = (variant: Variant[]) => {
        feedbackFormValues.current.variant = variant;
        if (visibleError) {
            closeError();
        }
    };

    const onCloseFeedbackModal = () => {
        Analytics.sendHHEventButtonClick('vacancy_view_stat_tab_similar_feedback_modal_close', {
            vacancyId,
        });

        feedbackFormValues.current = { ...DEFAULT_FORM_VALUES };
        closeError();
        closeModal();
    };

    const onSendFeedback = () => {
        const { variant, text } = feedbackFormValues.current;

        if (!variant.length && !text) {
            showError();
            return;
        }

        Analytics.sendHHEvent('tab_stat_feedback_sent', {
            vacancyId,
            variant: JSON.stringify(variant),
            text,
        });

        onCloseFeedbackModal();
        addNotification(Notification, {
            props: {
                addon: <CheckCircleFilledSize24 initialColor="positive" />,
                children: trls[TrlKeys.notificationText],
                showClose: true,
            },
        });
    };

    const leftButtons = (
        <Button mode="secondary" style="accent" onClick={onCloseFeedbackModal}>
            {trls[TrlKeys.buttonCancel]}
        </Button>
    );

    const rightButtons = (
        <Button mode="primary" style="accent" onClick={onSendFeedback} disabled={visibleError}>
            {trls[TrlKeys.buttonSend]}
        </Button>
    );

    const modalContent = (
        <div ref={feedbackModalRef}>
            <VSpacingContainer default={16}>
                <UncontrolledTextArea
                    ref={(el) => {
                        if (el) {
                            el.setAttribute('maxlength', String(MAX_TEXT_LENGTH));
                        }
                    }}
                    onChange={onChangeText}
                    placeholder={trls[TrlKeys.placeholder]}
                    maxLength={MAX_TEXT_LENGTH}
                    onValidate={DUMMY_VALIDATE}
                />
                <VariantChipChooser onSelect={onSelectVariant} />
                {visibleError ? (
                    <Text typography="label-3-regular" style="negative">
                        {trls[TrlKeys.errorText]}
                    </Text>
                ) : (
                    <VSpacing default={20} />
                )}
            </VSpacingContainer>
        </div>
    );

    return (
        <>
            <Card stretched borderWidth="default" padding={24} borderRadius={24} ref={feedbackCardRef}>
                <div className={styles.feedbackCard}>
                    <Text typography="label-2-regular" style="secondary">
                        {trls[TrlKeys.description]}
                    </Text>
                    <Button
                        mode="secondary"
                        style="neutral"
                        onClick={onShowModal}
                        stretched={isXS}
                        data-qa="tab-stat-btn-feedback"
                    >
                        {trls[TrlKeys.buttonText]}
                    </Button>
                </div>
            </Card>
            <Modal
                visible={visibleModal}
                onClose={onCloseFeedbackModal}
                actions={<Action mode="secondary" onClick={onCloseFeedbackModal} icon={CrossOutlinedSize24} />}
                title={trls[TrlKeys.title]}
                footer={<ActionBar primaryActions={rightButtons} secondaryActions={leftButtons} />}
                size="large"
            >
                {modalContent}
            </Modal>
            <BottomSheet
                header={
                    <NavigationBar
                        title={trls[TrlKeys.title]}
                        showDivider="always"
                        right={<Action icon={CrossOutlinedSize24} onClick={onCloseFeedbackModal} />}
                    />
                }
                visible={visibleModal}
                onClose={onCloseFeedbackModal}
                showDivider="with-scroll"
                footer={
                    <BottomSheetFooter>
                        {rightButtons}
                        {leftButtons}
                    </BottomSheetFooter>
                }
            >
                {modalContent}
            </BottomSheet>
        </>
    );
};

export default translation(FeedbackCard);
