import { useState, RefObject, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import Analytics from '@hh.ru/analytics-js';
import { Tooltip, Button, VSpacing, Text, Title } from '@hh.ru/magritte-ui';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { usePush } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Notices from 'Modules/Notices';
import translation from 'src/components/translation';
import useAnalyticsEventShownOnce from 'src/hooks/useAnalyticsEventShownOnce';
import { useSelector } from 'src/hooks/useSelector';
import { useIsHhru } from 'src/hooks/useSites';
import { AnalyzeResultType } from 'src/models/canvacVacancySalaryData';
import { setVacancyViewActiveTab, VacancyViewTab } from 'src/models/vacancyViewTab';

const TrlKeys = {
    title: 'employer.vacancy.tab.health.onboarding.title',
    text: 'employer.vacancy.tab.health.onboarding.text',
    buttons: {
        show: 'employer.vacancy.tab.health.onboarding.btn.show',
    },
};

const INFOTIP_NAME = 'vacancyTabHealthOnboarding';

interface TabTooltipProps {
    activatorRef: RefObject<HTMLSpanElement>;
}

const vacancyStatOnboardingOnTabField = makeSetStoreField('vacancyStatOnboardingOnTab');

const TabTooltip: TranslatedComponent<TabTooltipProps> = ({ activatorRef, trls }) => {
    const [show, setShow] = useState(true);
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const showTip = useSelector((state) => state.vacancyStatOnboardingOnTab);
    const isHHRu = useIsHhru();
    const dispatch = useDispatch();
    const push = usePush();

    const fromParam = useSelector((state) => state.router.location.query?.from);
    const isFromAds = fromParam === 'onboarding_tab_stat';
    const analyticsParams = {
        vacancyId,
        isFromAds,
    };

    const openStatTab = useCallback(
        (vacancyId: string) => {
            push(
                `/vacancy/${vacancyId}?activeTab=stat${isFromAds ? '&from=onboarding_tab_stat' : ''}`,
                { tab: VacancyViewTab.Stat },
                { cancelFetchingData: true }
            );
            dispatch(
                setVacancyViewActiveTab({
                    active: VacancyViewTab.Stat,
                })
            );
        },
        [dispatch, isFromAds, push]
    );

    const canvacSalaryData = useSelector((state) => state.canvacVacancySalaryData);

    const visible =
        show && showTip && isHHRu && canvacSalaryData?.analyzeResultType === AnalyzeResultType.CalculatedFull;

    const tooltipRef = useAnalyticsEventShownOnce({
        name: 'vacancy_view_health_tab_onboarding',
        ...analyticsParams,
    });

    const closeTipHandler = () => {
        setShow(false);
        Notices.markAsViewed(INFOTIP_NAME);
        dispatch(vacancyStatOnboardingOnTabField(false));
    };

    const onCloseHandler = () => {
        Analytics.sendHHEventButtonClick('vacancy_view_health_tab_onboarding_close', analyticsParams);
        closeTipHandler();
    };

    const onClickHandler = () => {
        openStatTab(String(vacancyId));
        Analytics.sendHHEventButtonClick('vacancy_view_health_tab_onboarding_show', analyticsParams);
        closeTipHandler();
    };

    return (
        <Tooltip
            showClose={true}
            visible={visible}
            activatorRef={activatorRef}
            closeByClickOutside
            onClose={onCloseHandler}
            placement="right-center"
            forcePlacement={true}
            guardDistanceToActivator={28}
            maxWidth={380}
            buttons={
                <Button
                    onClick={onClickHandler}
                    style="contrast"
                    mode="secondary"
                    size="small"
                    stretched
                    data-qa="tab-stat-onboarding-btn-show"
                >
                    {trls[TrlKeys.buttons.show]}
                </Button>
            }
        >
            <div ref={tooltipRef}>
                <Title Element="h3" size="small">
                    {trls[TrlKeys.title]}
                </Title>
                <VSpacing default={6} />
                <Text>{trls[TrlKeys.text]}</Text>
            </div>
        </Tooltip>
    );
};

export default translation(TabTooltip);
