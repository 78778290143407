import { FC } from 'react';

import vacancySuitableItemButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/vacancy_suitable_item_button_click';
import { Card, Link as MagritteLink, Text, VSpacingContainer } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import urlParser from 'bloko/common/urlParser';

import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';
import { VacancyCompany, VacancySearchItem } from 'src/types/search/vacancy/card';

import Company from 'src/components/SuitableVacancy/Company';
import Compensation from 'src/components/SuitableVacancy/Compensation';

interface SuitableVacancyProps {
    vacancyId: VacancySearchItem['vacancyId'];
    vacancyName: VacancySearchItem['name'];
    companyId: VacancyCompany['id'];
    companyVisibleName: VacancyCompany['visibleName'];
    companyDepartment: VacancyCompany['department'];
    companyLogo?: string;
    area: VacancySearchItem['area'];
    compensation: VacancySearchItem['compensation'];
    links: VacancySearchItem['links'];
}

const SuitableVacancy: FC<SuitableVacancyProps> = ({
    vacancyId,
    vacancyName,
    compensation,
    companyId,
    companyVisibleName,
    companyDepartment,
    companyLogo,
    area,
    links,
}) => {
    const isAnonymous = useSelector((state) => state.userType) === UserType.Anonymous;
    const url = urlParser(links.desktop);
    const hhtmFromLabel = isAnonymous ? 'similar_vacancies_sidebar' : 'suitable_vacancies_sidebar';
    const vacancyViewId = useSelector((state) => state.vacancyView?.vacancyId ?? 0);

    const onNameClick = () => {
        vacancySuitableItemButtonClick({
            hhtmSourceLabel: 'vacancy_side_block',
            vacancyId: vacancyViewId,
            vacancyTargetId: vacancyId,
        });
    };

    return (
        <Card stretched borderWidth="default" padding={24} borderRadius={24}>
            <VSpacingContainer default={24}>
                <MagritteLink
                    style="neutral"
                    Element={SPALink}
                    to={url.href}
                    additionalQueryParams={{ hhtmFromLabel }}
                    target="_blank"
                    onClick={onNameClick}
                >
                    <VSpacingContainer default={8}>
                        <Text typography="label-2-regular" data-qa="suitable-vacancy-name">
                            {vacancyName}
                        </Text>
                        <Text typography="subtitle-1-semibold">
                            <Compensation compensation={compensation} />
                        </Text>
                    </VSpacingContainer>
                </MagritteLink>
                <Company
                    id={companyId}
                    visibleName={companyVisibleName}
                    department={companyDepartment}
                    logo={companyLogo}
                    area={area}
                />
            </VSpacingContainer>
        </Card>
    );
};

export default SuitableVacancy;
