import { FC } from 'react';

import { Card, Skeleton, VSpacing } from '@hh.ru/magritte-ui';

import ContainerForMicroFrontend from 'src/components/CodeInjector/ContainerForMicroFrontend';

import ChartHeader from 'src/pages/VacancyView/components/VacancyStatTab/Chart/ChartHeader';

const Chart: FC = () => {
    return (
        <Card stretched borderWidth="default" padding={24} borderRadius={24}>
            <ChartHeader />
            <VSpacing default={24} />
            <ContainerForMicroFrontend
                preloadContent={<Skeleton height={120} borderRadius={24} loading />}
                place={'vacancyConversionChartSmall'}
            />
        </Card>
    );
};

export default Chart;
