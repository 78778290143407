import { startTransition, useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import Analytics from '@hh.ru/analytics-js';
import { Text, TextStyle, VSpacing } from '@hh.ru/magritte-ui';
import { usePush } from '@hh.ru/redux-spa-middleware';
import { SalaryRange } from '@hh.ru/types-hh-microcore';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import scrollToElement from 'bloko/common/scrollToElement';

import SalaryInfo from 'src/components/SalaryInfo';
import { SALARY_VACANCY_ADVICES } from 'src/components/VacancyModals/VacancyAdviceModal/constants';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { useIsHhru } from 'src/hooks/useSites';
import { CanvacBannerCase, CompetitionLevel } from 'src/models/canvacVacancySalaryData';
import { setVacancyViewActiveTab, VacancyViewTab } from 'src/models/vacancyViewTab';
import { NON_BREAKING_SPACE, EM_DASH, REGULAR_SPACE } from 'src/utils/constants/symbols';

import BannerWrapper from 'src/components/VacancyImportantBanners/BannerWrapper';

import styles from './vacancy-important-banners.less';

const TrlKeys = {
    salaryRange: {
        [SalaryRange.TooHigh]: 'employer.vacancy.VacancyImportantBanners.canvac.salaryRange.TOO_HIGH',
        [SalaryRange.High]: 'employer.vacancy.VacancyImportantBanners.canvac.salaryRange.HIGH',
        [SalaryRange.MatchMarket]: 'employer.vacancy.VacancyImportantBanners.canvac.salaryRange.MATCH_MARKET',
        [SalaryRange.Low]: 'employer.vacancy.VacancyImportantBanners.canvac.salaryRange.LOW',
        [SalaryRange.TooLow]: 'employer.vacancy.VacancyImportantBanners.canvac.salaryRange.TOO_LOW',
        [SalaryRange.TooWide]: 'employer.vacancy.VacancyImportantBanners.canvac.salaryRange.TOO_WIDE',
        [SalaryRange.Undefined]: 'employer.vacancy.VacancyImportantBanners.canvac.salaryRange.UNDEFINED',
        [SalaryRange.NotSpecified]: 'employer.vacancy.VacancyImportantBanners.canvac.salaryRange.NOT_SPECIFIED',
    },
    salaryRangeTitle: 'employer.vacancy.VacancyImportantBanners.canvac.salaryRange',
    salaryRangeTitle2: 'employer.vacancy.VacancyImportantBanners.canvac.salaryRange.2',
    salaryRangeTitleMatchMarket: 'employer.vacancy.VacancyImportantBanners.canvac.salaryRange.matchMarket',
    salaryRangeInterval: 'employer.vacancy.VacancyImportantBanners.canvac.salaryRange.interval',
    marketIsNotDefined: 'employer.vacancy.VacancyImportantBanners.canvac.salaryRange.marketIsNotDefined',
    salaryRangeIsNotDefined: 'employer.vacancy.VacancyImportantBanners.canvac.salaryRange.salaryRangeIsNotDefined',
    competitionLevel: {
        [CompetitionLevel.High]: {
            name: 'employer.vacancy.VacancyImportantBanners.canvac.competitionLevel.HIGH',
            description: 'employer.vacancy.VacancyImportantBanners.canvac.competitionLevel.HIGH.description',
        },
        [CompetitionLevel.Medium]: {
            name: 'employer.vacancy.VacancyImportantBanners.canvac.competitionLevel.MEDIUM',
            description: 'employer.vacancy.VacancyImportantBanners.canvac.competitionLevel.MEDIUM.description',
        },
        [CompetitionLevel.Low]: {
            name: 'employer.vacancy.VacancyImportantBanners.canvac.competitionLevel.LOW',
            description: 'employer.vacancy.VacancyImportantBanners.canvac.competitionLevel.LOW.description',
        },
        [CompetitionLevel.Undefined]: {
            name: 'employer.vacancy.VacancyImportantBanners.canvac.competitionLevel.UNDEFINED',
            description: '',
        },
    },
    competitionLevelTitle: 'employer.vacancy.VacancyImportantBanners.canvac.competitionLevel',
    responsesTitle: 'employer.vacancy.VacancyImportantBanners.canvac.responses',
    responses: {
        one: 'statportal.competitorAnalysisForVacancy.conversion.responses.one',
        some: 'statportal.competitorAnalysisForVacancy.conversion.responses.some',
        many: 'statportal.competitorAnalysisForVacancy.conversion.responses.many',
    },
    responsesDescription: 'employer.vacancy.VacancyImportantBanners.canvac.responses.description',
};

const COMPETITION_LEVEL_HIGHLIGHT: Record<CompetitionLevel, TextStyle> = {
    [CompetitionLevel.High]: 'negative',
    [CompetitionLevel.Medium]: 'warning',
    [CompetitionLevel.Low]: 'positive',
    [CompetitionLevel.Undefined]: 'primary',
};

const SALARY_RANGE_HIGHLIGHT_DOT = {
    [SalaryRange.Undefined]: undefined,
    [SalaryRange.NotSpecified]: styles.highlightedIntervalBad,
    [SalaryRange.TooWide]: styles.highlightedIntervalNormal,
    [SalaryRange.TooLow]: styles.highlightedIntervalBad,
    [SalaryRange.Low]: styles.highlightedIntervalBad,
    [SalaryRange.MatchMarket]: styles.highlightedIntervalGood,
    [SalaryRange.High]: styles.highlightedIntervalGood,
    [SalaryRange.TooHigh]: styles.highlightedIntervalGood,
};

export const getTextStyle = (salaryRange: SalaryRange, hasSalaryAdvice: boolean): TextStyle => {
    if (!hasSalaryAdvice && salaryRange === SalaryRange.MatchMarket) {
        return 'positive';
    }
    if ([SalaryRange.MatchMarket, SalaryRange.TooWide].includes(salaryRange)) {
        return 'warning';
    }
    if ([SalaryRange.High, SalaryRange.TooHigh].includes(salaryRange)) {
        return 'special';
    }
    return 'negative';
};

export const ANCHOR_TO_SECTION = {
    SalaryRangeCard: 'salary-range',
    CompetitionCard: 'competition',
    AnalysisAndCompetitors: 'analysisAndCompetitors',
};

interface CanvacBannerProps {
    vacancyId: number;
    canvacBannerCase: CanvacBannerCase;
}

const CanvacBanner: TranslatedComponent<CanvacBannerProps> = ({ vacancyId, canvacBannerCase, trls }) => {
    const dispatch = useDispatch();
    const push = usePush();
    const ref = useRef<HTMLDivElement>(null);
    const isHHRu = useIsHhru();

    const canvacVacancySalaryData = useSelector((state) => state.canvacVacancySalaryData);
    const adviceWithStatistics = useSelector((state) => state.vacancyAdviceModalData.adviceWithStatistics);
    const hasSalaryAdvice = adviceWithStatistics.some((item) => SALARY_VACANCY_ADVICES.includes(item.advice));
    const { tabs } = useSelector((state) => state.vacancyViewTab);
    const hasStatTab = tabs.includes(VacancyViewTab.Stat);

    useEffect(() => {
        if (!ref.current || !canvacBannerCase) {
            return undefined;
        }
        const { stopSpying } = Analytics.sendHHEventElementShown(ref.current, {
            name: canvacBannerCase === CanvacBannerCase.Salary ? 'important_banner_income' : 'important_banner_canvac',
            vacancyId,
            case: canvacBannerCase,
            salaryRange:
                canvacBannerCase === CanvacBannerCase.Salary ? canvacVacancySalaryData?.salaryRange : undefined,
            competitionLevel:
                canvacBannerCase === CanvacBannerCase.CompetitionLevel
                    ? canvacVacancySalaryData?.competitionLevel
                    : undefined,
        });
        return () => {
            stopSpying();
        };
    }, [canvacBannerCase, canvacVacancySalaryData, vacancyId]);

    const openStatTab = useCallback(
        (vacancyId: number, anchorId: string) => {
            push(
                `/vacancy/${vacancyId}?activeTab=stat#${anchorId}`,
                { tab: VacancyViewTab.Stat },
                { cancelFetchingData: true }
            );
            dispatch(
                setVacancyViewActiveTab({
                    active: VacancyViewTab.Stat,
                })
            );
            startTransition(() => {
                const anchor = document.querySelector(location.hash);
                if (anchor) {
                    scrollToElement(anchor, { centered: false, topOffset: -20 });
                }
            });
        },
        [dispatch, push]
    );

    if (!canvacVacancySalaryData?.competitionLevel) {
        return null;
    }
    const { marketRange, competitionLevel, salaryRange } = canvacVacancySalaryData;
    const colorTextStyle = getTextStyle(salaryRange, hasSalaryAdvice);

    const getSalaryRangeTitle = () => {
        switch (salaryRange) {
            case SalaryRange.TooWide:
                return trls[TrlKeys.salaryRangeTitle2];

            case SalaryRange.MatchMarket:
                return trls[TrlKeys.salaryRangeTitleMatchMarket];

            default:
                return trls[TrlKeys.salaryRangeTitle];
        }
    };

    const onClickSalaryBanner = () => {
        Analytics.sendHHEventButtonClick('important_banner_income_button', {
            vacancyId,
            case: canvacBannerCase,
            salaryRange,
        });
        if (!hasStatTab) {
            return;
        }
        if (isHHRu) {
            openStatTab(vacancyId, ANCHOR_TO_SECTION.SalaryRangeCard);
        } else {
            window.open(`/employer/statistics/competitor_analysis/vacancy/${vacancyId}`, '_blank');
        }
    };

    const onClickCompetitionBanner = () => {
        Analytics.sendHHEventButtonClick('important_banner_canvac_button', {
            vacancyId,
            case: canvacBannerCase,
            competitionLevel,
        });
        if (!hasStatTab) {
            return;
        }
        if (isHHRu) {
            openStatTab(vacancyId, ANCHOR_TO_SECTION.CompetitionCard);
        } else {
            window.open(
                `/employer/statistics/competitor_analysis/vacancy/${vacancyId}?activeTab=competitorsAnalysis`,
                '_blank'
            );
        }
    };

    if (canvacBannerCase === CanvacBannerCase.Salary) {
        return (
            <BannerWrapper dataQa="vacancy-important-banner-income" onClick={onClickSalaryBanner}>
                <div ref={ref}>
                    {salaryRange !== SalaryRange.Undefined && (
                        <>
                            <div className={styles.canvacBannerTitle}>
                                <Text Element="span" typography="label-3-regular">
                                    {getSalaryRangeTitle()}
                                    {NON_BREAKING_SPACE}
                                    {EM_DASH}
                                    {NON_BREAKING_SPACE}
                                </Text>
                                <Text Element="span" typography="label-3-regular" style={colorTextStyle}>
                                    {trls[TrlKeys.salaryRange[salaryRange]]}
                                </Text>
                            </div>
                            <VSpacing default={6} />
                        </>
                    )}
                    <div className={SALARY_RANGE_HIGHLIGHT_DOT[salaryRange]}>
                        {salaryRange === SalaryRange.Undefined ? (
                            trls[TrlKeys.salaryRangeIsNotDefined]
                        ) : (
                            <>
                                {trls[TrlKeys.salaryRangeInterval]}
                                {REGULAR_SPACE}
                                <SalaryInfo
                                    from={marketRange?.bottom}
                                    to={marketRange?.upper}
                                    currency={marketRange?.currency}
                                    defaultValue={trls[TrlKeys.marketIsNotDefined]}
                                    noWrap
                                />
                            </>
                        )}
                    </div>
                </div>
            </BannerWrapper>
        );
    }

    if (canvacBannerCase === CanvacBannerCase.CompetitionLevel) {
        return (
            <BannerWrapper dataQa="vacancy-important-banner-canvac" onClick={onClickCompetitionBanner}>
                <div ref={ref}>
                    <div className={styles.canvacBannerTitle}>
                        <Text Element="span" typography="label-3-regular">
                            {trls[TrlKeys.competitionLevelTitle]}
                            {NON_BREAKING_SPACE}
                            {EM_DASH}
                            {NON_BREAKING_SPACE}
                        </Text>
                        <Text
                            Element="span"
                            typography="label-3-regular"
                            style={COMPETITION_LEVEL_HIGHLIGHT[competitionLevel]}
                        >
                            {trls[TrlKeys.competitionLevel[competitionLevel].name]}
                        </Text>
                    </div>
                    <VSpacing default={6} />
                    {trls[TrlKeys.competitionLevel[competitionLevel].description]}
                </div>
            </BannerWrapper>
        );
    }

    return null;
};

export default translation(CanvacBanner);
