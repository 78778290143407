import { FC, ReactNode } from 'react';

import { VSpacing } from '@hh.ru/magritte-ui';

import VacancySuitableResumesBanner from 'src/components/VacancySuitableResumesBanner';
import { useSelector } from 'src/hooks/useSelector';
import { VacancyViewTab } from 'src/models/vacancyViewTab';

import { AIAssistantTabPaneContent } from 'src/pages/VacancyView/components/VacancyAIAssistantTab';
import VacancyHistoryTab from 'src/pages/VacancyView/components/VacancyHistoryTab';
import VacancyMainTab from 'src/pages/VacancyView/components/VacancyMainTab';
import VacancyManagersTab from 'src/pages/VacancyView/components/VacancyManagersTab';
import VacancyStatTab from 'src/pages/VacancyView/components/VacancyStatTab';
import TabPane from 'src/pages/VacancyView/components/VacancyTabs/TabPane';

interface VacancyTabPaneContentProps {
    employerReviews: ReactNode;
    backofficeRelatedVacancies: ReactNode;
}

const VacancyTabPaneContent: FC<VacancyTabPaneContentProps> = ({ employerReviews, backofficeRelatedVacancies }) => {
    const { active: activeTab, tabs } = useSelector((state) => state.vacancyViewTab);
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const vacancySuitableResumesBanner = <VacancySuitableResumesBanner vacancyId={vacancyId!} />;

    return (
        <>
            <TabPane index={VacancyViewTab.Main} value={activeTab}>
                <VacancyMainTab
                    employerReviews={employerReviews}
                    backofficeRelatedVacancies={backofficeRelatedVacancies}
                    vacancySuitableResumesBanner={vacancySuitableResumesBanner}
                />
            </TabPane>
            {tabs.includes(VacancyViewTab.Stat) && (
                <TabPane index={VacancyViewTab.Stat} value={activeTab} destroyInactive>
                    <VacancyStatTab vacancySuitableResumesBanner={vacancySuitableResumesBanner} />
                    <VSpacing default={24} />
                </TabPane>
            )}
            {tabs.includes(VacancyViewTab.History) && (
                <TabPane index={VacancyViewTab.History} value={activeTab} destroyInactive>
                    <VacancyHistoryTab vacancySuitableResumesBanner={vacancySuitableResumesBanner} />
                    <VSpacing default={24} />
                </TabPane>
            )}
            {tabs.includes(VacancyViewTab.Managers) && (
                <TabPane index={VacancyViewTab.Managers} value={activeTab} destroyInactive>
                    <VacancyManagersTab />
                    <VSpacing default={24} />
                </TabPane>
            )}
            {tabs.includes(VacancyViewTab.AIAssistant) && (
                <TabPane index={VacancyViewTab.AIAssistant} value={activeTab} destroyInactive>
                    <AIAssistantTabPaneContent />
                    <VSpacing default={24} />
                </TabPane>
            )}
        </>
    );
};

export default VacancyTabPaneContent;
