import { FC } from 'react';
import { Field } from 'react-final-form';

import { createStaticDataProvider, StaticDataFetcherItem } from '@hh.ru/magritte-common-data-provider';
import { Select } from '@hh.ru/magritte-ui';

import { TargetTopicEmployerState } from 'src/models/aiAssistant/aiAssistantHrSettings';
import { EmployerStateType } from 'src/models/negotiationTopic.types';

interface TypedStaticDataFetcherItem extends StaticDataFetcherItem {
    value: TargetTopicEmployerState;
}

const OPTIONS: TypedStaticDataFetcherItem[] = [
    { text: 'Первичный контакт', value: EmployerStateType.PhoneInterview },
    { text: 'Собеседование', value: EmployerStateType.Interview },
    { text: 'Никуда: оставить в неразобранных', value: EmployerStateType.Response },
    { text: 'Подумать', value: EmployerStateType.Consider },
    { text: 'Предложение о работе', value: EmployerStateType.Offer },
    { text: 'Выход на работу', value: EmployerStateType.Hired },
];

const OPTIONS_OBJECT = Object.fromEntries(
    OPTIONS.map((option) => {
        const { value } = option;

        return [value, { ...option }];
    })
);

const dataProvider = createStaticDataProvider(OPTIONS);

const FieldTargetTopicEmployerState: FC = () => {
    const formatValue = (value: TargetTopicEmployerState) => {
        return OPTIONS_OBJECT[value];
    };

    const parseValue = (value: TypedStaticDataFetcherItem) => {
        return value.value;
    };

    return (
        <Field name="targetTopicEmployerState" format={formatValue} parse={parseValue}>
            {({ input }) => (
                <Select
                    {...input}
                    type="label"
                    dataProvider={dataProvider}
                    triggerProps={{ size: 'medium', maxWidth: 2_000 }}
                />
            )}
        </Field>
    );
};

export default FieldTargetTopicEmployerState;
