import { FC } from 'react';

import { Card, Text } from '@hh.ru/magritte-ui';

const ModalOptions: FC = () => {
    return (
        <Card
            stretched
            borderRadius={24}
            padding={24}
            style="dreamy-secondary"
            data-qa="hr-ai-assistant__modal-description-card"
        >
            <Text typography="paragraph-2-regular" data-qa="hr-ai-assistant__modal-description-text">
                Дайте Ассистенту больше подробностей о вакансии, так он лучше выполнит свою работу. Настройки можно
                изменить в любой момент
            </Text>
        </Card>
    );
};

export default ModalOptions;
