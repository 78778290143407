import { useState, RefObject } from 'react';
import { useDispatch } from 'react-redux';

import Analytics from '@hh.ru/analytics-js';
import { Tooltip, Button, VSpacing, Text, Title } from '@hh.ru/magritte-ui';
import { ArrowDownOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { usePush } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Notices from 'Modules/Notices';
import StaticImg from 'src/components/StaticImg';
import { ANCHOR_TO_SECTION } from 'src/components/VacancyImportantBanners/CanvacBanner';
import translation from 'src/components/translation';
import useAnalyticsEventShownOnce from 'src/hooks/useAnalyticsEventShownOnce';
import { useSelector } from 'src/hooks/useSelector';
import { useIsHhru } from 'src/hooks/useSites';
import { AnalyzeResultType } from 'src/models/canvacVacancySalaryData';
import { VacancyViewTab } from 'src/models/vacancyViewTab';

import styles from './tooltip-onboarding.less';

const TrlKeys = {
    title: 'employer.vacancy.tab.health.blocks.onboarding.title',
    text1: 'employer.vacancy.tab.health.blocks.onboarding.text.1',
    text2: 'employer.vacancy.tab.health.blocks.onboarding.text.2',
    text3: 'employer.vacancy.tab.health.blocks.onboarding.text.3',
    buttons: {
        show: 'employer.vacancy.tab.health.blocks.onboarding.btn.show',
        close: 'employer.vacancy.tab.health.blocks.onboarding.btn.close',
    },
};

const INFOTIP_NAME = 'vacancyTabHealthBlocksOnboarding';

interface TooltipOnboardingProps {
    activatorRef: RefObject<HTMLSpanElement>;
}

const vacancyStatOnboardingOnTabBlocksField = makeSetStoreField('vacancyStatOnboardingOnTabBlocks');

const TooltipOnboarding: TranslatedComponent<TooltipOnboardingProps> = ({ activatorRef, trls }) => {
    const dispatch = useDispatch();
    const push = usePush();
    const [show, setShow] = useState(true);
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const showTip = useSelector((state) => state.vacancyStatOnboardingOnTabBlocks);
    const isHHRu = useIsHhru();

    const fromParam = useSelector((state) => state.router.location.query?.from);
    const analyticsParams = {
        vacancyId,
        isFromAds: fromParam === 'onboarding_tab_stat',
    };

    const canvacSalaryData = useSelector((state) => state.canvacVacancySalaryData);

    const visible =
        show && showTip && isHHRu && canvacSalaryData?.analyzeResultType === AnalyzeResultType.CalculatedFull;

    const tooltipRef = useAnalyticsEventShownOnce({
        name: 'vacancy_view_health_tab_blocks_onboarding',
        ...analyticsParams,
    });

    const closeOutside = () => setShow(false);

    const closeTipHandler = () => {
        setShow(false);
        Notices.markAsViewed(INFOTIP_NAME);
        dispatch(vacancyStatOnboardingOnTabBlocksField(false));
    };

    const onCloseHandler = () => {
        Analytics.sendHHEventButtonClick('vacancy_view_health_tab_blocks_onboarding_close', analyticsParams);
        closeTipHandler();
    };

    const onScrollHandler = () => {
        closeTipHandler();
        Analytics.sendHHEventButtonClick('vacancy_view_health_tab_blocks_onboarding_show', analyticsParams);
        push(
            `/vacancy/${vacancyId}?activeTab=stat#${ANCHOR_TO_SECTION.AnalysisAndCompetitors}`,
            { tab: VacancyViewTab.Stat },
            { cancelFetchingData: true }
        );
    };

    return (
        <Tooltip
            visible={visible}
            activatorRef={activatorRef}
            closeByClickOutside
            onClose={closeOutside}
            placement="right-top"
            forcePlacement={true}
            guardDistanceToActivator={28}
            buttons={
                <>
                    <Button
                        onClick={onCloseHandler}
                        style="contrast"
                        mode="secondary"
                        size="small"
                        stretched
                        data-qa="tab-stat-onboarding-btn-great"
                    >
                        {trls[TrlKeys.buttons.close]}
                    </Button>
                    <Button
                        onClick={onScrollHandler}
                        style="contrast"
                        mode="primary"
                        size="small"
                        stretched
                        data-qa="tab-stat-onboarding-btn-show"
                    >
                        {trls[TrlKeys.buttons.show]}
                        <span className={styles.tooltipOnboardingButtonIcon}>
                            <ArrowDownOutlinedSize16 />
                        </span>
                    </Button>
                </>
            }
        >
            <div className={styles.tooltipOnboardingContainer} ref={tooltipRef}>
                <StaticImg
                    className={styles.tooltipOnboardingImg}
                    path="/images/hh/vacancy/vacancy-stat-tab-blocks-onboarding.png"
                    alt={trls[TrlKeys.title]}
                />
            </div>
            <VSpacing default={16} />
            <Title Element="h3" size="small">
                {trls[TrlKeys.title]}
            </Title>
            <VSpacing default={2} />
            <Text>
                <ul className={styles.tooltipOnboardingList}>
                    <li>{trls[TrlKeys.text1]}</li>
                    <li>{trls[TrlKeys.text2]}</li>
                    <li>{trls[TrlKeys.text3]}</li>
                </ul>
            </Text>
        </Tooltip>
    );
};

export default translation(TooltipOnboarding);
