import { FC } from 'react';

import { Text } from '@hh.ru/magritte-ui';

const ModalTitle: FC = () => {
    return (
        <Text typography="title-4-semibold" data-qa="hr-ai-assistant__modal-title">
            Настройте AI-ассистента для вакансии
        </Text>
    );
};

export default ModalTitle;
