import { FC } from 'react';

import { Text } from '@hh.ru/magritte-ui';
import {
    BellOutlinedSize24,
    BubbleSquareOutlinedSize24,
    MagnifierOutlinedSize24,
    QuestionCircleOutlinedSize24,
} from '@hh.ru/magritte-ui/icon';

import styles from './styles.less';

const Description: FC = () => {
    const descriptionItems = [
        {
            Icon: MagnifierOutlinedSize24,
            text: 'Самостоятельно находит подходящих специалистов и отправляет им персонализированные приглашения',
            dataQaText: 'hr-ai-assistant__description-item-text-search',
            dataQaIcon: 'hr-ai-assistant__description-item-icon-search',
        },
        {
            Icon: BubbleSquareOutlinedSize24,
            text: 'Мгновенно отвечает кандидатам и разъясняет детали вакансии, поддерживая их интерес и ускоряя найм',
            dataQaText: 'hr-ai-assistant__description-item-text-dialog',
            dataQaIcon: 'hr-ai-assistant__description-item-icon-dialog',
        },
        {
            Icon: QuestionCircleOutlinedSize24,
            text: 'Собирает недостающую информацию, не перегружая лишними вопросами',
            dataQaText: 'hr-ai-assistant__description-item-text-gather-info',
            dataQaIcon: 'hr-ai-assistant__description-item-icon-gather-info',
        },
        {
            Icon: BellOutlinedSize24,
            text: 'Сообщает, когда кандидат готов к диалогу, чтобы вы могли общаться только с заинтересованными людьми',
            dataQaText: 'hr-ai-assistant__description-item-text-notify',
            dataQaIcon: 'hr-ai-assistant__description-item-icon-notify',
        },
    ];

    return (
        <ul className={styles.descriptionContainer}>
            {descriptionItems.map(({ Icon, text, dataQaText, dataQaIcon }, index) => (
                <li key={index} className={styles.descriptionItem}>
                    <Icon borderRadius={12} padding={10} backgroundStyle="secondary" data-qa={dataQaIcon} />
                    <Text typography="subtitle-2-semibold" data-qa={dataQaText}>
                        {text}
                    </Text>
                </li>
            ))}
        </ul>
    );
};

export default Description;
