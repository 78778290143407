import { FC, MouseEventHandler } from 'react';

import { Button, ActionBar, useBreakpoint } from '@hh.ru/magritte-ui';

interface ModalFooterProps {
    formId: string;
}

const ModalFooter: FC<ModalFooterProps> = ({ formId }) => {
    const { isMobile } = useBreakpoint();

    const handleSubmitClick: MouseEventHandler<HTMLElement> = () => {};
    const handleCancelClick: MouseEventHandler<HTMLElement> = () => {};

    return (
        <ActionBar
            type={isMobile ? 'mobile' : 'modal'}
            primaryActions={
                <Button
                    mode="primary"
                    style="accent"
                    form={formId}
                    type="submit"
                    data-qa="hr-ai-assistant__modal-button-submit"
                    onClick={handleSubmitClick}
                >
                    Продолжить
                </Button>
            }
            secondaryActions={
                <Button
                    mode="tertiary"
                    style="accent"
                    data-qa="hr-ai-assistant__modal-button-cancel"
                    onClick={handleCancelClick}
                >
                    Назад
                </Button>
            }
        />
    );
};

export default ModalFooter;
