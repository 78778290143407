import { FC } from 'react';

import { BettaIcon } from 'src/components/AIAssistant';

import styles from './styles.less';

const TabLabel: FC = () => {
    return (
        <div className={styles.labelContainer}>
            <div>AI-ассистент</div>
            <BettaIcon className={styles.labelIcon} />
        </div>
    );
};

export default TabLabel;
