import { useEffect, useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';

import AdsExternalBanner from 'src/components/Banner/AdsExternalBanner';
import { useSelector } from 'src/hooks/useSelector';
import { BannerPlace } from 'src/models/banners';

const getBannersMap = (banners, isOdd) => {
    if (isOdd) {
        return {
            bannerL: banners[BannerPlace.VACANCY_VIEW_SERP_FIRST_ODD_L],
            bannerSM: banners[BannerPlace.VACANCY_VIEW_SERP_FIRST_ODD_S_M],
            bannerXS: banners[BannerPlace.VACANCY_VIEW_SERP_FIRST_ODD_XS],
        };
    }
    return {
        bannerL: banners[BannerPlace.VACANCY_VIEW_SERP_FIRST_L],
        bannerSM: banners[BannerPlace.VACANCY_VIEW_SERP_FIRST_S_M],
        bannerXS: banners[BannerPlace.VACANCY_VIEW_SERP_FIRST_XS],
    };
};

const VacancySerpBanner = ({ isOdd, position, adsPositionInfo, bannerNum }) => {
    const isWebView = useSelector((state) => state.isWebView);
    const banners = useSelector((state) => state.banners);
    const adsVacancyParams = useSelector((state) => state.adsVacancyParams);
    const vacancyId = useSelector((state) => state.vacancyView?.vacancyId);
    const wrapperRef = useRef(null);

    useEffect(() => {
        if (isWebView) {
            return;
        }
        if (wrapperRef.current) {
            Analytics.sendHHEventElementShown(wrapperRef.current, {
                name: 'clickme_banner',
                vacancyId,
                position: bannerNum + 4,
            });
        }
    }, [bannerNum, isWebView, vacancyId]);

    const { bannerL, bannerSM, bannerXS } = getBannersMap(banners, isOdd);
    if (isWebView || (!bannerXS?.length && !bannerSM?.length && !bannerL?.length)) {
        return null;
    }

    const sendClickAnalytics = () => {
        Analytics.sendHHEventButtonClick('clickme_banner', {
            vacancyId,
            position: bannerNum + 4,
        });
    };

    return (
        <div ref={wrapperRef}>
            {bannerL?.length > 0 && (
                <div className="banner-place-wrapper_show-on-l">
                    <div className="serp-special" onClick={sendClickAnalytics}>
                        <AdsExternalBanner
                            {...bannerL[0]}
                            cId={position ? `position_${position}_${bannerL[0].cId}` : bannerL[0].cId}
                            bannerAdTargeting={adsVacancyParams}
                            positionInfo={adsPositionInfo}
                        />
                    </div>
                </div>
            )}
            {bannerSM?.length > 0 && (
                <div className="banner-place-wrapper_show-on-s-m">
                    <div className="serp-special" onClick={sendClickAnalytics}>
                        <AdsExternalBanner
                            {...bannerSM[0]}
                            cId={position ? `position_${position}_${bannerSM[0].cId}` : bannerSM[0].cId}
                            bannerAdTargeting={adsVacancyParams}
                            positionInfo={adsPositionInfo}
                        />
                    </div>
                </div>
            )}
            {bannerXS?.length > 0 && (
                <div className="banner-place-wrapper_show-on-xs">
                    <div className="serp-special" onClick={sendClickAnalytics}>
                        <AdsExternalBanner
                            {...bannerXS[0]}
                            cId={position ? `position_${position}_${bannerXS[0].cId}` : bannerXS[0].cId}
                            bannerAdTargeting={adsVacancyParams}
                            positionInfo={adsPositionInfo}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default VacancySerpBanner;
