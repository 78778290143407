import { Dispatch } from 'react';
import { AnyAction } from 'redux';

import Analytics from '@hh.ru/analytics-js';
import { HSpacing } from '@hh.ru/magritte-ui';
import { AstrostarOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import Conversion from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import useShowAdviceModal from 'src/components/VacancyModals/VacancyAdviceModal/useShowAdviceModal';
import translation from 'src/components/translation';
import useAnalyticsEventShownOnce from 'src/hooks/useAnalyticsEventShownOnce';
import { useSelector } from 'src/hooks/useSelector';

import BannerWrapper from 'src/components/VacancyImportantBanners/BannerWrapper';

import styles from './vacancy-important-banners.less';

const TrlKeys = {
    adviceCounter: {
        one: 'employer.myVacancies.advice.count.one',
        some: 'employer.myVacancies.advice.count.some',
        many: 'employer.myVacancies.advice.count.many',
    },
    adviceTitle: 'employer.vacancy.VacancyImportantBanners.advice',
};

interface AdviceBannerProps {
    dispatchModal: Dispatch<AnyAction>;
}

const AdviceBanner: TranslatedComponent<AdviceBannerProps> = ({ dispatchModal, trls }) => {
    const vacancyView = useSelector((state) => state.vacancyView);
    const { vacancyId = 0, name: vacancyName } = vacancyView;
    const vacancyAreaName = vacancyView?.area?.name || '';
    const canvacVacancySalaryData = useSelector((state) => state.canvacVacancySalaryData);
    const adviceWithStatistics = useSelector((state) => state.vacancyAdviceModalData.adviceWithStatistics);
    const compensation = useSelector((state) => state.vacancyView.compensation);

    const adviceBannerRef = useAnalyticsEventShownOnce({
        name: 'important_banner_advice',
        vacancyId,
    });

    const { openModal: openModalHandler } = useShowAdviceModal();

    const openModal = async () => {
        if (!canvacVacancySalaryData) {
            return;
        }
        Analytics.sendHHEventButtonClick(`important_banner_advice_button`, {
            vacancyId: Number(vacancyId),
        });
        const { marketRange, salaryRange, numberOfSimilarVacancies } = canvacVacancySalaryData;
        await openModalHandler(
            {
                vacancy: {
                    vacancyId,
                    vacancyName,
                    vacancyAreaName,
                },
                adviceWithStatistics,
                compensation,
                marketRange,
                salaryRange,
                numberOfSimilarVacancies,
            },
            dispatchModal
        );
    };

    return (
        <BannerWrapper dataQa="vacancy-important-banner-advice" onClick={openModal}>
            <div className={styles.adviceBanner} ref={adviceBannerRef}>
                <AstrostarOutlinedSize16 initialColor="special" />
                <HSpacing default={8} />
                <span>
                    {trls[TrlKeys.adviceTitle]}
                    {': '}
                    <Conversion
                        value={adviceWithStatistics.length}
                        one={trls[TrlKeys.adviceCounter.one]}
                        some={trls[TrlKeys.adviceCounter.some]}
                        many={trls[TrlKeys.adviceCounter.many]}
                    />
                </span>
            </div>
        </BannerWrapper>
    );
};

export default translation(AdviceBanner);
