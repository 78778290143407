import { FC } from 'react';

import { Avatar, Cell, CellText, Link } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';

import { VacancyCompany, VacancySearchItem } from 'src/types/search/vacancy/card';

import styles from './styles.less';

interface CompanyProps {
    id: VacancyCompany['id'];
    visibleName: VacancyCompany['visibleName'];
    department: VacancyCompany['department'];
    logo?: string;
    area: VacancySearchItem['area'];
}

const Company: FC<CompanyProps> = ({ id, visibleName, department, logo }) => {
    const content = (
        <Cell
            left={
                <div className={styles.avatarWrapper}>
                    <Avatar
                        shapeCircle
                        size={40}
                        mode="image"
                        image={logo}
                        fallbackMode="placeholder"
                        placeholder="city"
                        aria-label={visibleName}
                    />
                </div>
            }
        >
            <CellText maxLines={1}>{visibleName}</CellText>
        </Cell>
    );

    if (!id) {
        return content;
    }

    const departmentCode = department?.['@code'] || '';
    const departmentQuery = departmentCode ? `?dpt=${departmentCode}` : '';
    const employerUrl = `/employer/${id}${departmentQuery}`;

    return (
        <Link style="neutral" Element={SPALink} to={employerUrl} target="_blank">
            {content}
        </Link>
    );
};

export default Company;
