import { FC } from 'react';
import { Field } from 'react-final-form';

import { TextArea } from '@hh.ru/magritte-ui';

import { MAX_ADDITIONAL_CONTEXT_LENGTH } from 'src/components/AIAssistant/constants';
import { FormValuesHrSettings } from 'src/components/AIAssistant/types';

const DUMMY_TEXT_AREA_VALIDATION = () => {
    //     do nothing
};

const FieldAdditionalContext: FC = () => {
    return (
        <Field<FormValuesHrSettings['additionalContext']> name="additionalContext" parse={(value) => value ?? ''}>
            {({ input }) => (
                <TextArea
                    {...input}
                    placeholder="Например, укажите зарплатную вилку"
                    layout="fill-horizontal"
                    data-qa="hr-ai-assistant__modal-field-additional-context"
                    maxLength={MAX_ADDITIONAL_CONTEXT_LENGTH}
                    onValidate={DUMMY_TEXT_AREA_VALIDATION}
                />
            )}
        </Field>
    );
};

export default FieldAdditionalContext;
