import { FormValuesHrSettings } from 'src/components/AIAssistant/types';
import { AIAssistantHrSettings } from 'src/models/aiAssistant/aiAssistantHrSettings';

export function adaptSettingsToFormValues(aiAssistantHrSettings: AIAssistantHrSettings): FormValuesHrSettings {
    const { questions, additionalContext, targetTopicEmployerState } = aiAssistantHrSettings;

    return {
        questions: questions.length ? questions : [''],
        additionalContext,
        targetTopicEmployerState,
    };
}

export function adaptFormValuesToSettings(values: FormValuesHrSettings): AIAssistantHrSettings {
    const { questions, additionalContext, targetTopicEmployerState } = values;
    return {
        questions: questions.filter(Boolean),
        additionalContext,
        targetTopicEmployerState,
    };
}
