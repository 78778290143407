import { FC, PropsWithChildren } from 'react';

import { Text, VSpacing } from '@hh.ru/magritte-ui';

interface ContainerProps extends PropsWithChildren {
    title: string;
    description?: string;
}

const ContainerField: FC<ContainerProps> = ({ title, description, children }) => {
    return (
        <>
            <Text typography="subtitle-1-semibold">{title}</Text>
            {description && (
                <>
                    <VSpacing default={12} />
                    <Text typography="paragraph-2-regular">{description}</Text>
                </>
            )}
            <VSpacing default={24} />
            {children}
        </>
    );
};

export default ContainerField;
