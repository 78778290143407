import { Dispatch, FC, RefObject, useCallback, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import { AnyAction } from 'redux';

import { useBreakpoint, VSpacing } from '@hh.ru/magritte-ui';
import { ArrowPathClockwiseOutlinedSize24, ArrowUpOutlinedSize24, WalletOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { usePush } from '@hh.ru/redux-spa-middleware';
import urlParser from 'bloko/common/urlParser';

import AbstractControls from 'src/components/AbstractControls';
import {
    BasicTriggerPropsWithoutTriggerName,
    ControlsTriggerActionType,
    TriggerType,
} from 'src/components/ControlsTrigger';
import VacancyTrigger from 'src/components/EmployerVacancies/VacancyTrigger';
import ArchiveAction from 'src/components/VacancyActions/ArchiveAction';
import ArchivedVacanciesVisibilityAction from 'src/components/VacancyActions/ArchivedVacanciesVisibilityAction';
import AutoProlongationAction from 'src/components/VacancyActions/AutoProlongationAction';
import DeleteAction from 'src/components/VacancyActions/DeleteAction';
import DuplicateAction from 'src/components/VacancyActions/DuplicateAction';
import EditAction from 'src/components/VacancyActions/EditAction';
import OptionAction from 'src/components/VacancyActions/OptionAction';
import PfpTopupAction from 'src/components/VacancyActions/PfpTopupAction';
import PrintAction from 'src/components/VacancyActions/PrintAction';
import ProlongateAction from 'src/components/VacancyActions/ProlongateAction';
import ResponsesLinkAction from 'src/components/VacancyActions/ResponsesLinkAction';
import RestoreAction from 'src/components/VacancyActions/RestoreAction';
import RestoreFastAction from 'src/components/VacancyActions/RestoreFastAction';
import UpgradeAction from 'src/components/VacancyActions/UpgradeAction';
import VideoInterviewAction from 'src/components/VacancyActions/VideoInterviewAction';
import { sendEventShownMenuItems } from 'src/components/VacancyActions/sendVacancyActionsAnalytics';
import useAdaptiveVacancyActions from 'src/components/VacancyActions/useAdaptiveVacancyActions';
import VideoInterviewInfo from 'src/components/VacancyView/VideoInterviewInfo';
import usePropertiesBundle from 'src/hooks/usePropertiesBundle';
import { useSelector } from 'src/hooks/useSelector';
import { VacancyProperty, Properties } from 'src/models/vacancyProperties';

import VacancyAction, { VacancyViewActionProps } from 'src/pages/VacancyView/components/VacancyAction';

interface VacancyEmployerActionsProps {
    checkedVacancies: RefObject<Record<number, number[]>>;
    dispatchModal: Dispatch<AnyAction>;
    isBackoffice: boolean;
}

interface ControlItemConfigProps {
    name: string;
    condition: boolean;
    Component: FC<BasicTriggerPropsWithoutTriggerName>;
    additionalProps?: Record<string, unknown>;
    actionType?: ControlsTriggerActionType;
}

const VacancyEmployerActions: FC<VacancyEmployerActionsProps> = ({ checkedVacancies, dispatchModal, isBackoffice }) => {
    const vacancyView = useSelector((state) => state.vacancyView);
    const availableVacancyActions = useSelector((state) => state.availableVacancyActions);
    const hasVideoInterview = useSelector((state) => state.hasVideoInterview);
    const freeRestoreDays = useSelector((state) => state.vacancyInternalInfo?.freeRestoreDays);
    const permissions = useSelector((state) => state.permissions);
    const propertiesBundle = usePropertiesBundle();
    const sendAnaliticsRef = useRef([]);
    const push = usePush();

    const [menuItems, setMenuItems] = useState<string[]>([]);
    const { isXS } = useBreakpoint();

    const { containerRef, layoutCallback } = useAdaptiveVacancyActions(setMenuItems);
    const buttonsContainerRef = useRef<HTMLDivElement>(null);

    const afterSuccessArchiveModal = useCallback(
        (params: { isFeedbackSent: boolean; responseQuery: string }) => {
            const feedbackNotificationParam = params.isFeedbackSent ? '&feedbackSent=true' : '';
            const query = params.responseQuery;

            if (query) {
                push(`${location.pathname}${query}${feedbackNotificationParam}`);
            } else {
                window.location.reload();
            }
        },
        [push]
    );

    const onVacancyArchiveSuccess = useCallback(() => {
        const url = urlParser(window.location.href);
        url.params.makeVacancyVisible = 'false';
        push(url.href);
    }, [push]);

    const freeRenewalParameters = (vacancyView.vacancyProperties.properties as Properties)
        .find((property) => property.propertyType === VacancyProperty.ZpManualRenewal)
        ?.parameters?.reduce((acc: Record<string, string>, param: { key: string; value: string }) => {
            if (param.key === 'lastActivationTimeIso' || param.key === 'intervalMinutes') {
                acc[param.key] = param.value;
            }
            return acc;
        }, {});

    const controls: ControlItemConfigProps[] = useMemo(
        () => [
            {
                name: 'responses-link',
                condition: !!vacancyView.canViewResponses && !isXS,
                Component: ResponsesLinkAction as FC,
            },
            {
                name: 'prolongate',
                condition: !!availableVacancyActions.update,
                Component: ProlongateAction as FC,
                additionalProps: {
                    afterSuccessAction: ({ queryParams }: Record<string, string>) => {
                        const url = urlParser(location.pathname);
                        Object.entries(queryParams).map(([key, value]) => (url.params[key] = value));
                        push(url.href);
                    },
                    freeRenewalParameters,
                    icon: <ArrowUpOutlinedSize24 initialColor="accent" />,
                },
            },
            {
                name: 'option-toggle',
                condition: !!availableVacancyActions.toggleOption,
                Component: OptionAction as FC,
                additionalProps: {
                    hasActiveOption: vacancyView.vacancyProperties?.calculatedStates?.[propertiesBundle]?.optionPremium,
                },
            },
            {
                name: 'edit',
                condition: !!availableVacancyActions.edit,
                Component: EditAction as FC,
                additionalProps: {
                    iconOnly: isXS && !menuItems.includes('edit'),
                    ...(isBackoffice && { employerId: vacancyView.company.id }),
                    triggerTarget: '_self',
                },
            },
            {
                name: 'archive',
                condition: !!availableVacancyActions.archive,
                Component: ArchiveAction as FC,
                additionalProps: {
                    vacancyName: vacancyView.name,
                    onArchiveSuccess: afterSuccessArchiveModal,
                },
                actionType: ControlsTriggerActionType.NEGATIVE,
            },
            {
                name: 'auto-prolongation-toggle',
                condition:
                    !!availableVacancyActions.enableAutoProlongation ||
                    !!availableVacancyActions.disableAutoProlongation,
                Component: AutoProlongationAction as unknown as FC,
                additionalProps: {
                    managerIds: [vacancyView.managerId],
                    isAutoProlongationPlanned: availableVacancyActions.disableAutoProlongation,
                    additionalAnalyticsParams: {
                        vacancy_id: vacancyView.vacancyId,
                    },
                },
            },
            {
                name: 'pfp-topup',
                condition: !!vacancyView.vacancyProperties?.calculatedStates?.[propertiesBundle]?.payForPerformance,
                Component: PfpTopupAction as FC,
                additionalProps: {
                    hhtmFromLabel: 'vacancy-controls',
                    icon: <WalletOutlinedSize24 />,
                },
            },
            {
                name: 'upgrade',
                condition: !!availableVacancyActions.upgrade,
                Component: UpgradeAction as FC,
                additionalProps: {
                    employerId: vacancyView.company.id,
                    from: 'from-vacancy',
                },
            },
            {
                name: 'duplicate',
                condition: !!availableVacancyActions.duplicate,
                Component: DuplicateAction as FC,
            },
            {
                name: 'delete',
                condition: !!availableVacancyActions.delete,
                Component: DeleteAction as FC,
                actionType: ControlsTriggerActionType.NEGATIVE,
            },
            {
                name: 'restore',
                condition: !!availableVacancyActions.restore,
                Component: (freeRestoreDays && freeRestoreDays > 0 ? RestoreFastAction : RestoreAction) as FC,
                additionalProps: {
                    iconOnly: isXS,
                    icon: <ArrowPathClockwiseOutlinedSize24 />,
                },
            },
            {
                name: 'hide-archived-vacancies',
                condition: !!availableVacancyActions.hide,
                Component: ArchivedVacanciesVisibilityAction as FC,
                additionalProps: {
                    archivationType: 'ARCHIVED',
                    archivedVacancies: [vacancyView.vacancyId],
                    permissions,
                    onSuccessAction: onVacancyArchiveSuccess,
                    hideActionName: isXS,
                },
            },
            {
                name: 'restore-archived-vacancies',
                condition: !!availableVacancyActions.restoreToArchive,
                Component: ArchivedVacanciesVisibilityAction as FC,
                additionalProps: {
                    archivationType: 'DELETED_BY_CLIENT',
                    archivedVacancies: [vacancyView.vacancyId],
                    permissions,
                    onSuccessAction: onVacancyArchiveSuccess,
                    hideActionName: isXS,
                },
            },
            {
                name: 'videointerview',
                condition: hasVideoInterview,
                Component: VideoInterviewAction as FC,
                additionalProps: {
                    showInfo: !menuItems.includes('videointerview'),
                    vacanciesIds: [vacancyView.vacancyId],
                    labelEntry: 'vacancy',
                },
            },
            {
                name: 'print',
                condition: !!availableVacancyActions.print,
                Component: PrintAction as FC,
                additionalProps: { vacancyId: vacancyView.vacancyId },
            },
        ],
        [
            freeRenewalParameters,
            vacancyView.canViewResponses,
            vacancyView.vacancyProperties,
            vacancyView.company.id,
            vacancyView.name,
            vacancyView.managerId,
            vacancyView.vacancyId,
            isXS,
            availableVacancyActions.update,
            availableVacancyActions.toggleOption,
            availableVacancyActions.edit,
            availableVacancyActions.archive,
            availableVacancyActions.enableAutoProlongation,
            availableVacancyActions.disableAutoProlongation,
            availableVacancyActions.upgrade,
            availableVacancyActions.duplicate,
            availableVacancyActions.delete,
            availableVacancyActions.restore,
            availableVacancyActions.hide,
            availableVacancyActions.restoreToArchive,
            availableVacancyActions.print,
            propertiesBundle,
            menuItems,
            isBackoffice,
            afterSuccessArchiveModal,
            freeRestoreDays,
            permissions,
            onVacancyArchiveSuccess,
            hasVideoInterview,
            push,
        ]
    );

    const ControlComponent = useMemo(
        () =>
            (
                props: Omit<
                    VacancyViewActionProps,
                    'buttonsContainerRef' | 'sendAnaliticsRef' | 'layoutCallback' | 'vacancyId'
                >
            ) => (
                <VacancyAction
                    buttonsContainerRef={buttonsContainerRef}
                    sendAnaliticsRef={sendAnaliticsRef}
                    layoutCallback={layoutCallback}
                    vacancyId={vacancyView.vacancyId!}
                    {...props}
                />
            ),
        [layoutCallback, vacancyView.vacancyId]
    );

    const controlProps = useMemo(
        () => ({
            Component: ControlComponent,
            checkedVacancies: checkedVacancies.current,
            dispatchModal,
            vacanciesIds: [vacancyView.vacancyId],
            vacancyId: vacancyView.vacancyId!,
        }),
        [ControlComponent, checkedVacancies, dispatchModal, vacancyView.vacancyId]
    );

    const renderedMenuItems = useMemo(
        () => controls.filter(({ name, condition }) => condition && menuItems.includes(name)),
        [controls, menuItems]
    );

    return (
        <>
            {isXS && (
                <>
                    <ResponsesLinkAction
                        triggerType={TriggerType.Button}
                        {...controlProps}
                        Component={(props) => (
                            <VacancyAction
                                buttonsContainerRef={buttonsContainerRef}
                                sendAnaliticsRef={sendAnaliticsRef}
                                layoutCallback={layoutCallback}
                                vacancyId={vacancyView.vacancyId!}
                                {...props}
                            />
                        )}
                    />
                    <VSpacing default={12} />
                </>
            )}
            <div ref={containerRef} className="vacancy-actions">
                <div className="vacancy-actions__main" ref={buttonsContainerRef}>
                    {controls.map(
                        ({ Component, name, condition, additionalProps }) =>
                            condition && (
                                <Component
                                    key={name}
                                    triggerType={TriggerType.Button}
                                    {...controlProps}
                                    {...additionalProps}
                                />
                            )
                    )}
                </div>
                <div
                    className={classnames('vacancy-actions__more', {
                        'vacancy-actions__more_hidden': !renderedMenuItems.length,
                    })}
                >
                    <VideoInterviewInfo showInfo={hasVideoInterview && menuItems.includes('videointerview')}>
                        <AbstractControls
                            controlsList={renderedMenuItems}
                            controlProps={{
                                ...controlProps,
                                triggerType: TriggerType.ActionList,
                                Component: VacancyTrigger,
                            }}
                            displayAsMenu
                            dataQa={{
                                menu: 'vacancy-page-actions-menu',
                                button: 'vacancy-page-actions-button',
                            }}
                            onMenuStateChange={(visible) => {
                                if (visible) {
                                    sendEventShownMenuItems(menuItems);
                                }
                            }}
                            actionListPlacement="right-top"
                        />
                    </VideoInterviewInfo>
                </div>
            </div>
        </>
    );
};

export default VacancyEmployerActions;
