import { FC } from 'react';

import { SquaresIntersectedOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { BasicTriggerProps, BasicTriggerPropsWithoutTriggerName } from 'src/components/ControlsTrigger';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import getMenuButtonIconProps from 'src/components/VacancyActions/getMenuButtonIconProps';
import { UPDATE_CREATE_PERMISSION } from 'src/components/VacancyActions/permissions';

const TrlKeys = {
    duplicate: 'employer.vacancy.button.duplicate',
};

interface DuplicateActionProps extends BasicTriggerPropsWithoutTriggerName {
    Component: FC<BasicTriggerProps>;
    permissions: string[];
    vacancyId: number;
}

const DuplicateAction: TranslatedComponent<DuplicateActionProps> = ({
    Component,
    trls,
    vacancyId,
    triggerType,
    additionalAnalyticsParams,
}) => {
    const permissions = useSelector((state) => state.permissions);
    const triggerName = 'duplicate';
    const iconProps = getMenuButtonIconProps({
        triggerType,
        triggerName,
        icon: <SquaresIntersectedOutlinedSize24 />,
    });

    return (
        <Component
            triggerName={triggerName}
            triggerType={triggerType}
            triggerHref={`/employer/vacancy/duplicate/${vacancyId}`}
            isPermitted={permissions.includes(UPDATE_CREATE_PERMISSION)}
            additionalAnalyticsParams={additionalAnalyticsParams}
            {...iconProps}
        >
            {trls[TrlKeys.duplicate]}
        </Component>
    );
};

export default translation(DuplicateAction);
