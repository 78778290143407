import { Fragment, useRef } from 'react';

import { Text, VSpacing } from '@hh.ru/magritte-ui';
import Column, { ColumnsRow } from 'bloko/blocks/column';

import VacancyActions from 'src/components/VacancyView/VacancyActions';
import VacancyAdditionalAccepts from 'src/components/VacancyView/VacancyAdditionalAccepts';
import VacancyCompany from 'src/components/VacancyView/VacancyCompany';
import VacancyCompensationFrequency from 'src/components/VacancyView/VacancyCompensationFrequency';
import VacancyContacts from 'src/components/VacancyView/VacancyContacts';
import VacancyDescription from 'src/components/VacancyView/VacancyDescription';
import VacancyDriverLicense from 'src/components/VacancyView/VacancyDriverLicense';
import VacancyEmployment from 'src/components/VacancyView/VacancyEmployment';
import VacancyFooter from 'src/components/VacancyView/VacancyFooter';
import VacancyIsArchived from 'src/components/VacancyView/VacancyIsArchived';
import VacancyKeySkills from 'src/components/VacancyView/VacancyKeySkills';
import VacancyMap from 'src/components/VacancyView/VacancyMap';
import VacancyOnlineUsersCounter from 'src/components/VacancyView/VacancyOnlineUsersCounter';
import VacancyPreviewTools from 'src/components/VacancyView/VacancyPreviewTools';
import VacancyResponseQuestions from 'src/components/VacancyView/VacancyResponseQuestions';
import VacancySimilarResumes from 'src/components/VacancyView/VacancySimilarResumes';
import VacancyTitle from 'src/components/VacancyView/VacancyTitle';
import useIsVisibleBody from 'src/components/VacancyView/hooks/useIsVisibleBody';

const VacancyBody = ({ contactsNodeRef, vacancyBodyFooterNodeRef, isEmployerView = false }) => {
    const resumeFormNodeRef = useRef();
    const { isVisibleBody, setIsVisibleBody } = useIsVisibleBody();

    return (
        <Fragment>
            <VacancyTitle />
            <VacancyCompany />
            <VacancyOnlineUsersCounter />
            <VacancyIsArchived isHiddenSwitcher={isVisibleBody} setVisibilityBody={setIsVisibleBody} />
            {isVisibleBody && (
                <Fragment>
                    <VacancyActions
                        contactsNodeRef={contactsNodeRef}
                        vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                        resumeFormNodeRef={resumeFormNodeRef}
                    />
                    <ColumnsRow>
                        <Column xs="4" s="8" m="8" l="10">
                            <div className="vacancy-description">
                                <Text>
                                    <VacancyCompensationFrequency />
                                    <VacancyEmployment />
                                    <VSpacing default={32} />
                                    <VacancyDescription />
                                </Text>
                                <VacancyAdditionalAccepts isEmployerView={isEmployerView} />
                                <VacancyKeySkills />
                                <VacancyDriverLicense isEmployerView={isEmployerView} />
                                <VacancyContacts blockRef={contactsNodeRef} isMagritte />
                                <VacancyResponseQuestions vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef} />
                                <VacancyMap newEmployerVacancyMap={isEmployerView} />
                                <VacancyPreviewTools />
                                <VacancySimilarResumes />
                            </div>
                        </Column>
                    </ColumnsRow>
                    <VacancyFooter vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef} />
                </Fragment>
            )}
        </Fragment>
    );
};

export default VacancyBody;
