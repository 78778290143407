import { ReactNode, useRef } from 'react';

import { useBreakpoint, Text, VSpacingContainer, VSpacing } from '@hh.ru/magritte-ui';
import { CompensationMode, SalaryRange } from '@hh.ru/types-hh-microcore';
import Column, { ColumnsRow } from 'bloko/blocks/column';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { ANCHOR_TO_SECTION } from 'src/components/VacancyImportantBanners/CanvacBanner';
import HealthTabBlocksTooltip from 'src/components/VacancyStatOnboarding';
import useBranding from 'src/components/VacancyView/hooks/useBranding';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { useIsHhru } from 'src/hooks/useSites';
import { AnalyzeResultType } from 'src/models/canvacVacancySalaryData';
import { LoadingState } from 'src/models/loadingState';

import Chart from 'src/pages/VacancyView/components/VacancyStatTab/Chart';
import CompetitionCard from 'src/pages/VacancyView/components/VacancyStatTab/CompetitionCard';
import CompetitorCard from 'src/pages/VacancyView/components/VacancyStatTab/CompetitorCard';
import FeedbackCard from 'src/pages/VacancyView/components/VacancyStatTab/FeedbackCard';
import LoadingResult from 'src/pages/VacancyView/components/VacancyStatTab/LoadingResult';
import MetricCard from 'src/pages/VacancyView/components/VacancyStatTab/MetricCard';
import NoFoundData from 'src/pages/VacancyView/components/VacancyStatTab/NoFoundData';
import SalaryRangeCard from 'src/pages/VacancyView/components/VacancyStatTab/SalaryRangeCard';
import useFetchVacancyAnalytics from 'src/pages/VacancyView/components/VacancyStatTab/useFetchVacancyAnalytics';
import useScrollToCard from 'src/pages/VacancyView/components/VacancyStatTab/useScrollToCard';

const TrlKeys = {
    analysisAndCompetitors: 'employer.vacancy.tab.stat.analysisAndCompetitors.title',
};

interface VacancyStatTabProps {
    vacancySuitableResumesBanner?: ReactNode;
}

const VacancyStatTab: TranslatedComponent<VacancyStatTabProps> = ({ vacancySuitableResumesBanner, trls }) => {
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const canvacSalaryData = useSelector((state) => state.canvacVacancySalaryData);
    const vacancies = useSelector((state) => state.canvacSimilarVacancies);
    const compensation = useSelector((state) => state.vacancyView.compensation);
    const { loadingState } = useSelector((state) => state.vacancyConversionStatistics);

    const isShow = loadingState === LoadingState.Success;
    const isHHRu = useIsHhru();
    const isShowAnalyticsInfo =
        isHHRu && [LoadingState.Success, LoadingState.Error].includes(loadingState) && vacancyId;
    const { isM, isS, isXS } = useBreakpoint();
    const { isBranded } = useBranding();
    const tabContentRef = useRef<HTMLDivElement>(null);

    const hasMarketRange = canvacSalaryData?.marketRange && Object.values(canvacSalaryData?.marketRange).every(Boolean);
    const hasSalaryRange = canvacSalaryData?.salaryRange && canvacSalaryData?.salaryRange !== SalaryRange.Undefined;

    // До похода за прокси-компонентом графика ЗП, определяем нужен ли он, т.к. канвак не умеет не в месячную зарплату
    const withSalaryRangeCard =
        ('noCompensation' in compensation || compensation?.mode === CompensationMode.Month) &&
        hasMarketRange &&
        hasSalaryRange;

    useScrollToCard();
    useFetchVacancyAnalytics(Boolean(withSalaryRangeCard));

    return (
        <ColumnsRow>
            <Column xs="4" s="8" m="8" l="10">
                <div ref={tabContentRef}>
                    <HealthTabBlocksTooltip activatorRef={tabContentRef} />
                    <LoadingResult loadingState={loadingState} vacancyId={vacancyId} />
                    {isShow && (
                        <VSpacingContainer default={16}>
                            <MetricCard />
                            <Chart />
                        </VSpacingContainer>
                    )}
                    {isShowAnalyticsInfo && (
                        <>
                            <VSpacing default={40} />
                            <VSpacingContainer default={24} xs={16}>
                                <Text id={ANCHOR_TO_SECTION.AnalysisAndCompetitors} typography="title-5-semibold">
                                    {trls[TrlKeys.analysisAndCompetitors]}
                                </Text>
                                {canvacSalaryData?.analyzeResultType === AnalyzeResultType.CalculatedFull ? (
                                    <>
                                        {withSalaryRangeCard && <SalaryRangeCard vacancyId={vacancyId} />}
                                        <CompetitionCard canvacSalaryData={canvacSalaryData} vacancyId={vacancyId} />
                                        {isShow && <CompetitorCard vacancies={vacancies} vacancyId={vacancyId} />}
                                        <FeedbackCard />
                                    </>
                                ) : (
                                    <NoFoundData
                                        analyzeResultType={canvacSalaryData?.analyzeResultType}
                                        vacancyId={vacancyId}
                                    />
                                )}
                            </VSpacingContainer>
                        </>
                    )}
                    {(isS || isXS || (isM && isBranded)) && (
                        <>
                            <VSpacing default={40} />
                            {vacancySuitableResumesBanner}
                        </>
                    )}
                </div>
            </Column>
        </ColumnsRow>
    );
};

export default translation(VacancyStatTab);
