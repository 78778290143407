import { Fragment, useEffect, useRef } from 'react';

import Column from 'bloko/blocks/column';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import RelatedVacancies from 'src/components/RelatedVacancies/LazyWrapper';
import ResumeAdditionalDataCollector from 'src/components/ResumeAdditionalDataCollector';
import VacancyResponsePopup from 'src/components/VacancyResponsePopup';
import CountriesProfileVisibilityAgreementPopup from 'src/components/VacancyResponsePopup/CountriesProfileVisibilityAgreementPopup';
import RelocationWarningPopup from 'src/components/VacancyResponsePopup/RelocationWarningPopup';
import VacancyDefaultContainer from 'src/components/VacancyView/DefaultContainer';
import VacancyBodyRedesigned from 'src/components/VacancyView/VacancyBodyRedesigned';
import VacancyBrandedBody from 'src/components/VacancyView/VacancyBrandedBody';
import useBranding from 'src/components/VacancyView/hooks/useBranding';
import useShowResponse from 'src/components/VacancyView/useShowResponse';
import { useSelector } from 'src/hooks/useSelector';
import { addTabListener, removeTabListener } from 'src/utils/viewDuration';

import VacancySidebar from 'src/pages/VacancyView/components/VacancySidebar';
import useVacancyViewsCountNotification from 'src/pages/VacancyView/components/useVacancyViewsCountNotification';

const VacancyView = ({ vacancyView }) => {
    const viewDuration = useSelector((state) => state.viewDuration);
    const relatedVacanciesRef = useRef();
    const contactsNodeRef = useRef();
    const vacancyBodyFooterNodeRef = useRef();
    const { isBranded } = useBranding();
    useShowResponse();
    useVacancyViewsCountNotification();

    const breakpoint = useBreakpoint();
    const isXs = breakpoint === Breakpoint.XS;

    useEffect(() => {
        addTabListener(viewDuration);
        return () => {
            removeTabListener();
        };
    }, [viewDuration]);

    const relatedVacanciesBlock = (
        <div className="noprint">
            <Column xs="4" s="8" m="12" l="10">
                <RelatedVacancies forEmployer />
            </Column>
            <Column xs="4" s="8" m="12" l="10">
                <RelatedVacancies blockRef={relatedVacanciesRef} />
            </Column>
        </div>
    );

    return (
        <Fragment>
            {isBranded ? (
                <VacancyBrandedBody
                    contactsNodeRef={contactsNodeRef}
                    vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                    bottomContent={relatedVacanciesBlock}
                    isRedesign
                />
            ) : (
                <VacancyDefaultContainer
                    isRedesign
                    rightColumn={
                        <Column xs="0" s="0" m="0" l="6" container>
                            <VacancySidebar
                                fullListRef={relatedVacanciesRef}
                                vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                            />
                        </Column>
                    }
                >
                    <VacancyBodyRedesigned
                        contactsNodeRef={contactsNodeRef}
                        vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                    />
                    {relatedVacanciesBlock}
                </VacancyDefaultContainer>
            )}
            <RelocationWarningPopup vacancyId={vacancyView.vacancyId} />
            <CountriesProfileVisibilityAgreementPopup vacancyId={vacancyView.vacancyId} />
            <VacancyResponsePopup
                vacancyId={vacancyView.vacancyId}
                vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                isXs={isXs}
            />
            <ResumeAdditionalDataCollector />
        </Fragment>
    );
};

export default VacancyView;
