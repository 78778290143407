import formatDate from 'Modules/formatDate';

/**
 * Возвращает интервал дат, объединяя одинаковые даты, месяцы и года, например
 * - 31.12.2020 - 31.12.2020 -> 31 декабря 2020
 * - 11.02.2020 - 15.02.2020 -> 11 февраля – 15 февраля 2020
 * - 01.01.2020 - 01.02.2020 -> 1 января – 1 февраля 2020
 * - 01.01.2020 - 01.01.2021 -> 1 января 2020 – 1 января 2021
 * @param short - использовать сокращенную запись месяца 1.01.2020 вместо 1 января 2020
 */
export const formatDatesInterval = (start: Date, end: Date, short: boolean): string => {
    const formatFull = short ? 'd.MM.yyyy' : 'd MMMM yyyy';
    const formatWithoutYear = short ? 'd.MM' : 'd MMMM';

    const formattedStartDate = formatDate(start, formatFull);
    const formattedEndDate = formatDate(end, formatFull);

    if (!formattedStartDate || !formattedEndDate) {
        return '';
    }
    if (formattedStartDate === formattedEndDate) {
        return formattedEndDate;
    }
    if (start.getFullYear() === end.getFullYear()) {
        const formattedStartDateWithoutYear = formatDate(start, formatWithoutYear);
        return `${formattedStartDateWithoutYear} – ${formattedEndDate}`;
    }
    return `${formattedStartDate} – ${formattedEndDate}`;
};
