import { FC } from 'react';
import { Form, FormProps } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import { VSpacing } from '@hh.ru/magritte-ui';

import { FormValuesHrSettings } from 'src/components/AIAssistant/types';

import ContainerField from 'src/components/AIAssistant/components/FormHrSettings/ContainerField';
import FieldAdditionalContext from 'src/components/AIAssistant/components/FormHrSettings/FieldAdditionalContext';
import FieldQuestions from 'src/components/AIAssistant/components/FormHrSettings/FieldQuestions';
import FieldTargetTopicEmployerState from 'src/components/AIAssistant/components/FormHrSettings/FieldTargetTopicEmployerState';

interface FormSettingsProps {
    formId: string;
    initialValues: FormValuesHrSettings;
    onSubmit: FormProps<FormValuesHrSettings>['onSubmit'];
}

const FormSettings: FC<FormSettingsProps> = ({ formId, initialValues, onSubmit }) => {
    return (
        <Form<FormValuesHrSettings>
            initialValues={initialValues}
            onSubmit={onSubmit}
            subscription={{}}
            mutators={{ ...arrayMutators }}
        >
            {({ handleSubmit }) => (
                <form id={formId} onSubmit={handleSubmit}>
                    <ContainerField
                        title="Контрольный вопрос кандидату"
                        description="Вопрос, который вы обязательно хотели бы задать"
                    >
                        <FieldQuestions />
                    </ContainerField>

                    <VSpacing default={44} />

                    <ContainerField
                        title="Дополнительная информация о вакансии или компании"
                        description="Пригодится ассистенту для ответов на вопросы кандидатов"
                    >
                        <FieldAdditionalContext />
                    </ContainerField>

                    <VSpacing default={44} />

                    <ContainerField title="Куда переводить заинтересованных соискателей">
                        <FieldTargetTopicEmployerState />
                    </ContainerField>
                </form>
            )}
        </Form>
    );
};

export default FormSettings;
