import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import { useSelector } from 'src/hooks/useSelector';
import { CanvacSimilarVacancy, setCanvacSimilarVacancies } from 'src/models/canvacSimilarVacancies';
import { LoadingState } from 'src/models/loadingState';
import { MicroFrontends, updateMicroFrontends } from 'src/models/microFrontends';
import { VacancyAdvice } from 'src/models/vacancy/vacancyAdvices.types';
import { setVacancyConversionStatistics, VacancyConversionStatistics } from 'src/models/vacancyConversionStatistics';
import fetcher from 'src/utils/fetcher';

const GET_VACANCY_ANALYTICS_URL = '/shards/vacancy/get_vacancy_analytics';

declare global {
    interface FetcherGetApi {
        [GET_VACANCY_ANALYTICS_URL]: {
            queryParams: {
                vacancyId: number;
                withSalaryRangeCard: boolean;
            };
            response: {
                vacancies: CanvacSimilarVacancy[];
                advices: VacancyAdvice[];
                microFrontends: MicroFrontends;
                vacancyConversionStatistics: VacancyConversionStatistics;
            };
        };
    }
}

const setVacancyAdviceWithStatistics = makeSetStoreField('vacancyAdvices');

const useFetchVacancyAnalytics = (withSalaryRangeCard: boolean) => {
    const dispatch = useDispatch();
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const { loadingState } = useSelector((state) => state.vacancyConversionStatistics);

    const fetchVacancyAnalytics = useCallback(
        async (vacancyId: number, signal: AbortSignal) => {
            try {
                const response = await fetcher.get(GET_VACANCY_ANALYTICS_URL, {
                    params: { vacancyId, withSalaryRangeCard: Boolean(withSalaryRangeCard) },
                    signal,
                });
                dispatch(updateMicroFrontends(response.microFrontends));
                dispatch(
                    setVacancyConversionStatistics({
                        loadingState: LoadingState.Success,
                        data: response.vacancyConversionStatistics,
                    })
                );
                if (response?.vacancies) {
                    dispatch(setCanvacSimilarVacancies(response.vacancies));
                }
                if (response?.advices) {
                    dispatch(setVacancyAdviceWithStatistics(response.advices));
                }
            } catch (_) {
                dispatch(
                    setVacancyConversionStatistics({
                        loadingState: LoadingState.Error,
                        data: null,
                    })
                );
            }
        },
        [withSalaryRangeCard, dispatch]
    );

    useEffect(() => {
        const abortController = new AbortController();
        if (loadingState === LoadingState.None && vacancyId) {
            void fetchVacancyAnalytics(vacancyId, abortController.signal);
        }
        return () => abortController.abort();
    }, [fetchVacancyAnalytics, loadingState, vacancyId]);
};

export default useFetchVacancyAnalytics;
