import { useState, useMemo, useCallback, useEffect } from 'react';
import { useField, FieldRenderProps, Field } from 'react-final-form';

import { Card } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FieldSet, { ResultRenderProps } from 'src/components/Forms/FieldSet';
import CheckboxWithLabel from 'src/components/VacancyCreate/common/CheckboxWithLabel';
import FormItem from 'src/components/VacancyCreate/common/FormItem';
import getFieldRules, { Rules } from 'src/components/VacancyCreate/utils/getFieldRules';
import { parseNumberInput } from 'src/components/VacancyCreate/utils/parsers';
import translation from 'src/components/translation';
import { HiringPlanType, hiringPlanDefaultFormValue } from 'src/models/employerVacancies/vacancyHiringPlans';

import PlanType from 'src/components/VacancyCreate/sections/PrimaryInfo/HiringPlan/Fields/PlanType';
import PlannedCountInput from 'src/components/VacancyCreate/sections/PrimaryInfo/HiringPlan/Fields/PlannedCountInput';
import StartDate from 'src/components/VacancyCreate/sections/PrimaryInfo/HiringPlan/Fields/StartDate';

import styles from './styles.less';

const HIRING_PLAN_PLANNED_COUNT_NAME = 'hiringPlan.plannedCount';
const HIRING_PLAN_START_DATE_NAME = 'hiringPlan.plannedHireDate';
const HIRING_PLAN_TYPE_NAME = 'hiringPlan.type';

interface HiringPlanFieldsProps {
    rules: Rules;
}

const TrlKeys = {
    noDate: 'hiringPlan.startDate.noDate',
};

const HiringPlanFields: TranslatedComponent<HiringPlanFieldsProps> = ({ trls, rules }) => {
    const { input } = useField<HiringPlanType>('hiringPlan');
    const hasPlannedCount = !!input.value?.plannedCount;
    const [shouldProlongateNoDate, setShouldProlongateNoDate] = useState(!hasPlannedCount);
    const plannedHireDate = input.value.plannedHireDate;
    const [withoutDate, setWithoutDate] = useState(!plannedHireDate);

    useEffect(() => {
        setWithoutDate(!plannedHireDate);
    }, [plannedHireDate]);

    const handleRemoveHiringPlan = useCallback(() => {
        input.onChange(null);
        setShouldProlongateNoDate(true);
    }, [input]);

    const { maxValue, minValue } = useMemo(() => getFieldRules(HIRING_PLAN_PLANNED_COUNT_NAME, rules), [rules]);

    const renderFieldSet = ({ combinedProps }: ResultRenderProps<unknown>) => {
        if (!combinedProps) {
            return null;
        }

        return (
            <>
                <FormItem>
                    <PlanType {...(combinedProps[HIRING_PLAN_TYPE_NAME].input as FieldRenderProps<string>['input'])} />
                </FormItem>
                <FormItem>
                    <div className={styles.additionalContainer}>
                        <div className={styles.additionalItem}>
                            <StartDate
                                withoutDate={withoutDate}
                                {...(combinedProps[HIRING_PLAN_START_DATE_NAME]
                                    .input as FieldRenderProps<string>['input'])}
                                shouldProlongateNoDate={shouldProlongateNoDate}
                                setShouldProlongateNoDate={setShouldProlongateNoDate}
                            />
                        </div>
                        <div className={styles.additionalItem}>
                            <Card
                                paddingTop={12}
                                paddingBottom={8}
                                paddingLeft={16}
                                paddingRight={16}
                                borderRadius={12}
                                stretched
                                borderWidth="default"
                            >
                                <CheckboxWithLabel
                                    title={trls[TrlKeys.noDate]}
                                    checked={withoutDate}
                                    onChange={(e) => setWithoutDate(e.target.checked)}
                                />
                            </Card>
                        </div>
                    </div>
                </FormItem>
            </>
        );
    };

    return (
        <FormItem>
            <Field name={HIRING_PLAN_PLANNED_COUNT_NAME} parse={parseNumberInput}>
                {({ input, meta }) => (
                    <PlannedCountInput
                        {...input}
                        meta={meta}
                        maxValue={maxValue as number}
                        minValue={minValue as number}
                        onRemoveHiringPlan={handleRemoveHiringPlan}
                    />
                )}
            </Field>
            {hasPlannedCount && (
                <FieldSet
                    subscription={{ combinedProps: true, metaSome: true }}
                    fields={[
                        {
                            name: HIRING_PLAN_START_DATE_NAME,
                        },
                        {
                            name: HIRING_PLAN_TYPE_NAME,
                            initialValue: input.value?.type || hiringPlanDefaultFormValue.type,
                        },
                    ]}
                >
                    {renderFieldSet}
                </FieldSet>
            )}
        </FormItem>
    );
};

export default translation(HiringPlanFields);
