import { useCallback } from 'react';

import { useNotification } from 'src/components/Notifications/Provider';
import VacancyResponseStandardNotification from 'src/components/Notifications/VacancyResponseStandardNotification';
import jobSearchStatusAfterResponse from 'src/components/VacancyResponseForm/JobSearchStatusNotification';
import resumeAutoHideNotification from 'src/components/VacancyResponseForm/ResumeAutoHideNotification';
import { useSelector } from 'src/hooks/useSelector';

interface NotificationParams {
    readPercent: number | null;
    topicId: number;
    vacancyId: number;
    openChatik: () => void;
}

type UseDispatchAfterResponseNotificationResult = (
    askJobSearchStatus: boolean,
    notificationParams: NotificationParams
) => void;

const useDispatchAfterResponseNotification = (): UseDispatchAfterResponseNotificationResult => {
    const publishedResumeHash = useSelector((state) => state.publishedForResponseResumeHash);
    const usedResume = useSelector((state) => state.vacancyView.responseInfo?.respondedWithResume);
    const { addNotification } = useNotification();

    return useCallback(
        (askJobSearchStatus, notificationParams) => {
            if (usedResume && usedResume.autoHideTime && publishedResumeHash === usedResume.hash) {
                addNotification(resumeAutoHideNotification, {
                    props: {
                        resumeHash: publishedResumeHash,
                        autoHideTime: usedResume.autoHideTime[0].string,
                    },
                });
                return;
            }

            if (askJobSearchStatus) {
                addNotification(jobSearchStatusAfterResponse, { props: notificationParams, uniqueType: true });
                return;
            }
            addNotification(VacancyResponseStandardNotification, {
                props: { vacancyId: notificationParams.vacancyId },
            });
        },
        [usedResume, publishedResumeHash, addNotification]
    );
};

export default useDispatchAfterResponseNotification;
