import { useRef } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from 'src/hooks/useSelector';
import { BillingPlatform } from 'src/models/price/product.types';
import { setSnippetData } from 'src/models/vacancySearchSnippet';
import { VacancySnippet } from 'src/types/search/vacancy/card';
import fetcher from 'src/utils/fetcher';

declare global {
    interface FetcherGetApi {
        '/shards/vacancy/get_snippet': {
            queryParams: {
                vacancyId: number;
                platform: BillingPlatform;
            };
            response: VacancySnippet;
        };
    }
}

const SNIPPET_DEFAULT: VacancySnippet = {};

type UseSnippetDataParam = [() => void, () => void];

const first = <T extends unknown[]>(arr: T): T[number] | undefined => {
    const [firstItem] = arr;
    return firstItem;
};

const useSnippetData = (): UseSnippetDataParam => {
    const dispatch = useDispatch();
    const { vacancyId, platforms } = useSelector((state) => state.vacancyView);
    const snippetRef = useRef<Record<number, VacancySnippet>>({ 0: SNIPPET_DEFAULT });

    const fetchSnippet = async () => {
        if (!vacancyId) {
            return;
        }

        if (snippetRef.current.hasOwnProperty(vacancyId)) {
            dispatch(setSnippetData(snippetRef.current[vacancyId]));
            return;
        }

        try {
            const response = await fetcher.get('/shards/vacancy/get_snippet', {
                params: {
                    vacancyId,
                    platform: first(platforms) ?? BillingPlatform.HH,
                },
            });
            dispatch(setSnippetData(response));
            snippetRef.current = { [vacancyId]: response };
        } catch (error) {
            console.error(error);
        }
    };

    const resetSnippet = () => dispatch(setSnippetData(SNIPPET_DEFAULT));

    return [resetSnippet, fetchSnippet];
};

export default useSnippetData;
