import { Fragment, useRef } from 'react';

import Column from 'bloko/blocks/column';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import RelatedVacancies from 'src/components/RelatedVacancies/LazyWrapper';
import ResumeAdditionalDataCollector from 'src/components/ResumeAdditionalDataCollector';
import VacancyResponsePopup from 'src/components/VacancyResponsePopup';
import CountriesProfileVisibilityAgreementPopup from 'src/components/VacancyResponsePopup/CountriesProfileVisibilityAgreementPopup';
import RelocationWarningPopup from 'src/components/VacancyResponsePopup/RelocationWarningPopup';
import VacancyDefaultContainer from 'src/components/VacancyView/DefaultContainer';
import VacancyBrandedBody from 'src/components/VacancyView/VacancyBrandedBody';

import useVacancyViewsCountNotification from 'src/pages/VacancyView/components/useVacancyViewsCountNotification';

const VacancyViewConstructor = ({ vacancyView }) => {
    const relatedVacanciesRef = useRef();
    const contactsNodeRef = useRef();
    const vacancyBodyFooterNodeRef = useRef();

    const breakpoint = useBreakpoint();
    const isXs = breakpoint === Breakpoint.XS;

    useVacancyViewsCountNotification();

    return (
        <Fragment>
            <VacancyBrandedBody
                contactsNodeRef={contactsNodeRef}
                isRedesign
                vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                hasTopPadding
            />
            <VacancyDefaultContainer>
                <div className="noprint">
                    <Column xs="4" s="8" m="8" l="10">
                        <RelatedVacancies forEmployer />
                    </Column>
                    <Column xs="4" s="8" m="8" l="10">
                        <RelatedVacancies blockRef={relatedVacanciesRef} />
                    </Column>
                </div>
            </VacancyDefaultContainer>
            <RelocationWarningPopup vacancyId={vacancyView.vacancyId} />
            <CountriesProfileVisibilityAgreementPopup vacancyId={vacancyView.vacancyId} />
            <VacancyResponsePopup
                vacancyId={vacancyView.vacancyId}
                vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                isXs={isXs}
            />
            <ResumeAdditionalDataCollector />
        </Fragment>
    );
};

export default VacancyViewConstructor;
