import { startTransition, useEffect } from 'react';

import { useSelector } from '@hh.ru/front-static-app';
import scrollToElement from 'bloko/common/scrollToElement';

import { LoadingState } from 'src/models/loadingState';

const useScrollToCard = () => {
    const { location } = useSelector((state) => state.router);
    const { loadingState } = useSelector((state) => state.vacancyConversionStatistics);

    useEffect(() => {
        if (location.hash && loadingState === LoadingState.Success) {
            startTransition(() => {
                const anchor = document.querySelector(location.hash);
                if (anchor) {
                    scrollToElement(anchor, { centered: false, topOffset: -20 });
                }
            });
        }
    });
};

export default useScrollToCard;
