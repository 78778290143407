import classNames from 'classnames';

import Analytics from '@hh.ru/analytics-js';
import { Card, Text, useBreakpoint, VSpacingContainer } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { AuctionCampaignStatus, CampaignCreationStatus } from 'src/models/auctionData';
import { VacancyAdvice } from 'src/models/vacancy/vacancyAdvices.types';

import RaiseVacancyButton from 'src/pages/VacancyView/components/VacancyStatTab/CompetitionCard/CompetitionAdvice/RaiseVacancyButton';
import UseResumeSearchButton from 'src/pages/VacancyView/components/VacancyStatTab/CompetitionCard/CompetitionAdvice/UseResumeSearchButton';
import UseSearchTopButton from 'src/pages/VacancyView/components/VacancyStatTab/CompetitionCard/CompetitionAdvice/UseSearchTopButton';

import styles from './competition-advice.less';

const TrlKeys = {
    [VacancyAdvice.UseSearchTop]: {
        title: 'employer.vacancy.tab.stat.advice.USE_SEARCH_TOP.title',
        description: 'employer.vacancy.tab.stat.advice.USE_SEARCH_TOP.description',
        action: 'employer.vacancy.tab.stat.advice.USE_SEARCH_TOP.action',
    },
    [VacancyAdvice.UseResumeSearch]: {
        title: 'employer.vacancy.tab.stat.advice.USE_RESUME_SEARCH.title',
        description: 'employer.vacancy.tab.stat.advice.USE_RESUME_SEARCH.description',
        action: 'employer.vacancy.tab.stat.advice.USE_RESUME_SEARCH.action',
    },
    [VacancyAdvice.RaiseVacancy]: {
        title: 'employer.vacancy.tab.stat.advice.RAISE_VACANCY.title',
        description: 'employer.vacancy.tab.stat.advice.RAISE_VACANCY.description',
        action: 'employer.vacancy.tab.stat.advice.RAISE_VACANCY.action',
    },
};

const COMPETITION_ADVICES = [
    VacancyAdvice.UseSearchTop,
    VacancyAdvice.UseResumeSearch,
    VacancyAdvice.RaiseVacancy,
] as const;

type TCompetitionAdvice = (typeof COMPETITION_ADVICES)[number];

const CompetitionAdvice: TranslatedComponent = ({ trls }) => {
    const { isXS } = useBreakpoint();

    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const advices = useSelector((state) => state.vacancyAdvices);
    const rawAdvice = advices.find((item): item is TCompetitionAdvice => COMPETITION_ADVICES.includes(item));

    const campaignCreationStatus = useSelector((state) => state.auctionData?.campaignCreationStatus);
    const campaignStatus = useSelector((state) => state.auctionData?.currentVacancyAuctionCampaign?.campaignStatus);
    const canUseSearchTop =
        campaignStatus !== AuctionCampaignStatus.Active &&
        campaignCreationStatus !== CampaignCreationStatus.CampaignCreated;
    const advice = rawAdvice === VacancyAdvice.UseSearchTop && canUseSearchTop ? VacancyAdvice.UseSearchTop : undefined;

    const canShowAdvice = advice && vacancyId;

    if (!canShowAdvice) {
        return null;
    }

    const onClickButton = (eventName: string) => {
        Analytics.sendHHEventButtonClick(eventName, { vacancyId });
    };

    const actionButtons = {
        [VacancyAdvice.UseSearchTop]: (
            <UseSearchTopButton vacancyId={vacancyId} isXS={isXS} sendEventClick={onClickButton}>
                {trls[TrlKeys[VacancyAdvice.UseSearchTop].action]}
            </UseSearchTopButton>
        ),
        [VacancyAdvice.UseResumeSearch]: (
            <UseResumeSearchButton vacancyId={vacancyId} isXS={isXS} sendEventClick={onClickButton}>
                {trls[TrlKeys[VacancyAdvice.UseResumeSearch].action]}
            </UseResumeSearchButton>
        ),
        [VacancyAdvice.RaiseVacancy]: <RaiseVacancyButton vacancyId={vacancyId} sendEventClick={onClickButton} />,
    };

    return (
        <Card stretched padding={12} borderRadius={12} style="secondary">
            <div className={classNames(styles.bannerContainer, { [styles.mobile]: isXS })}>
                <VSpacingContainer default={6}>
                    <Text typography="label-2-regular">{trls[TrlKeys[advice].title]}</Text>
                    <Text typography="paragraph-3-regular" style="secondary">
                        {trls[TrlKeys[advice].description]}
                    </Text>
                </VSpacingContainer>
                <div data-qa="tab-stat-btn-use-advice">{actionButtons[advice]}</div>
            </div>
        </Card>
    );
};

export default translation(CompetitionAdvice);
