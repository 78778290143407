import { useDispatch } from 'react-redux';

import Analytics from '@hh.ru/analytics-js';
import { CheckCircleFilledSize24, MinusCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { SUCCESS_CLASSIFICATION_CHANGES } from 'src/components/EmployerVacancies/constants';
import Notification from 'src/components/Notifications/Notification';
import { NotificationProps } from 'src/components/Notifications/Provider/types';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { resetRiseValues, resetRiseStatusValue } from 'src/models/employerVacanciesRise';

interface SnackbarRisePageNotificationProps extends NotificationProps {
    isRisePage?: boolean;
    triggerName?: string;
}

const TrlKeys = {
    aria: 'vacancy.rise.aria',
    successChangesSpend: 'vacancy.vacancyRisePage.classificationChangesSpend.success',
};

const SnackbarRisePageNotification: TranslatedComponent<SnackbarRisePageNotificationProps> = ({
    isRisePage,
    triggerName,
    trls,
    ...props
}) => {
    const dispatch = useDispatch();
    const error = useSelector((state) => state.employerVacanciesRise?.error);
    const success = useSelector((state) => state.employerVacanciesRise?.success);
    const successMessage = triggerName === SUCCESS_CLASSIFICATION_CHANGES ? trls[TrlKeys.successChangesSpend] : success;
    const isSnackMessageForRisePage = useSelector((state) => state.employerVacanciesRise?.isSnackMessageForRisePage);

    const handleClose = () => {
        if (isRisePage && isSnackMessageForRisePage) {
            dispatch(resetRiseStatusValue());
        } else {
            dispatch(resetRiseValues());
        }
    };

    if (error) {
        Analytics.sendHHEvent('prolongation_snackbar_error_shown', {
            message: error,
        });

        return (
            <Notification
                addon={<MinusCircleFilledSize24 initialColor="negative" />}
                aria-label={trls[TrlKeys.aria]}
                onClose={handleClose}
                showClose
                data-qa="prolongate-snackbar-error"
                {...props}
            >
                {error}
            </Notification>
        );
    }

    if (successMessage) {
        return (
            <Notification
                addon={<CheckCircleFilledSize24 initialColor="positive" />}
                aria-label={trls[TrlKeys.aria]}
                onClose={handleClose}
                showClose
                data-qa="prolongate-snackbar-success"
                {...props}
            >
                {successMessage}
            </Notification>
        );
    }

    return null;
};

export default translation(SnackbarRisePageNotification);
