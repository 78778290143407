import { Dispatch, FC, useRef, useState } from 'react';
import { AnyAction } from 'redux';

import HoverTip, { TipPlacement } from 'bloko/blocks/drop/Tip/HoverTip';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { BasicTriggerProps, BasicTriggerPropsWithoutTriggerName } from 'src/components/ControlsTrigger';
import { AfterSuccessAction } from 'src/components/VacancyModals/Prolongation';
import translation from 'src/components/translation';
import useProlongateAction from 'src/hooks/useProlongateAction';
import { useSelector } from 'src/hooks/useSelector';

import FreeRenewalTip from 'src/components/VacancyActions/FreeRenewalTip';
import { UPDATE_CREATE_PERMISSION } from 'src/components/VacancyActions/permissions';
import { useRiseAdvice } from 'src/components/VacancyActions/useRiseAdvice';

export interface ProlongateActionProps extends BasicTriggerPropsWithoutTriggerName {
    Component: FC<BasicTriggerProps>;
    afterSuccessAction: AfterSuccessAction;
    dispatchModal: Dispatch<AnyAction>;
    onClose?: VoidFunction;
    displayInOwnColumn?: boolean;
    vacanciesIds: number[];
    freeRenewalParameters?: {
        lastActivationTimeIso: string;
        intervalMinutes: string;
    };
    onClickCallback?: () => void;
    isPrimaryAction?: boolean;
}

const TrlKeys = {
    prolongate: 'vacancy.action.renewal',
};

const ProlongateAction: TranslatedComponent<ProlongateActionProps> = ({
    actionType,
    additionalAnalyticsParams,
    afterSuccessAction,
    Component,
    dispatchModal,
    displayInOwnColumn,
    freeRenewalParameters,
    icon,
    onClose,
    triggerType,
    trls,
    vacanciesIds,
    onClickCallback,
    isPrimaryAction = false,
}) => {
    const permissions = useSelector(({ permissions }) => permissions);
    const [isLoading, setLoading] = useState(false);

    const activatorRef = useRef(null);

    const breakpoint = useBreakpoint();
    const canShowHoverTip = [Breakpoint.M, Breakpoint.L].includes(breakpoint);

    const { lastActivationTimeIso, intervalMinutes } = freeRenewalParameters || {
        lastActivationTimeIso: null,
        intervalMinutes: null,
    };
    const isFreeRenewalAvailable = lastActivationTimeIso && intervalMinutes;

    const prolongateAction = useProlongateAction({
        dispatchModal,
        loadingCallback: setLoading,
        freeRenewalParameters: {
            lastActivationTimeIso,
            intervalMinutes,
        },
        onClose,
        trls,
    });

    const onTrigger = (isFromRunAction?: boolean) => {
        const currentSearchParams = new URLSearchParams(location.search);
        // если isFromAdvice будет true, то пришли сюда из редиректа совета,
        // нота(которая приходит в store.isVacancyAdvicePlaceAllowed)
        // говорит покажи совет в модалке, но
        // совета показать мы не может т.к флаг isAllowedRiseAdvice не разрешает,
        // и если ничего не сделать и никак не обработать, то будет показана модалка на поднять,
        // но без совета, поэтому не реагируем на такое
        const isFromAdvice = currentSearchParams.get('advice') === 'true' && isFromRunAction;
        if (isFromAdvice) {
            return undefined;
        }

        onClickCallback?.();
        return prolongateAction({
            vacanciesIds,
            afterSuccessAction,
            additionalAnalyticsParams,
        });
    };

    const { isAllowed: isAllowedRiseAdvice, resetTrigger } = useRiseAdvice();

    // будем вызывать этот колбэк а не `onTrigger` каждый раз когда разрешен показ совета в ноте
    const onTriggerAdvice = () => {
        resetTrigger();
        onClickCallback?.();
        return prolongateAction({
            vacanciesIds,
            afterSuccessAction,
            additionalAnalyticsParams,
        });
    };

    return (
        <Component
            actionType={actionType}
            additionalAnalyticsParams={additionalAnalyticsParams}
            batchLength={vacanciesIds.length}
            displayInOwnColumn={displayInOwnColumn}
            icon={icon}
            isLoading={isLoading}
            isPermitted={permissions.includes(UPDATE_CREATE_PERMISSION)}
            onTrigger={isAllowedRiseAdvice ? onTriggerAdvice : onTrigger}
            triggerName="prolongate"
            triggerType={triggerType}
            isPrimaryAction={isPrimaryAction}
        >
            {isFreeRenewalAvailable && canShowHoverTip ? (
                <HoverTip
                    placement={TipPlacement.Bottom}
                    render={() => (
                        <FreeRenewalTip lastFreeRenewal={lastActivationTimeIso} freeRenewalInterval={intervalMinutes} />
                    )}
                    host={!process.env.SSR ? document.body : null}
                    activatorRef={activatorRef}
                >
                    <span ref={activatorRef}>{trls[TrlKeys.prolongate]}</span>
                </HoverTip>
            ) : (
                trls[TrlKeys.prolongate]
            )}
        </Component>
    );
};

export default translation(ProlongateAction);
