import { Text, Loader, Card, Avatar } from '@hh.ru/magritte-ui';
import { InfoCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import useAnalyticsEventShownOnce from 'src/hooks/useAnalyticsEventShownOnce';
import { LoadingState } from 'src/models/loadingState';

import styles from './loading-result.less';

const TrlKeys = {
    message: 'statportal.report.dashboard.empty',
};

interface LoadingResultProps {
    loadingState: LoadingState;
    vacancyId?: number;
}

const LoadingResult: TranslatedComponent<LoadingResultProps> = ({ loadingState, vacancyId, trls }) => {
    const errorStateCardRef = useAnalyticsEventShownOnce({
        name: 'vacancy_view_stat_tab_error_chart_data',
        vacancyId,
    });

    if (loadingState === LoadingState.Success) {
        return null;
    }

    if (loadingState === LoadingState.Error) {
        return (
            <Card
                stretched
                verticalStretched
                borderWidth="default"
                borderRadius={16}
                padding={24}
                ref={errorStateCardRef}
            >
                <div className={styles.emptyPlaceholderCard}>
                    <Avatar
                        size={48}
                        shapeCircle
                        aria-label={trls[TrlKeys.message]}
                        mode="icon"
                        style="secondary"
                        icon={<InfoCircleFilledSize24 initialColor="tertiary" />}
                    ></Avatar>
                    <Text style="secondary">{trls[TrlKeys.message]}</Text>
                </div>
            </Card>
        );
    }

    return (
        <div className={styles.loadingWrapper}>
            <Loader size={24} />
        </div>
    );
};

export default translation(LoadingResult);
