import { Dispatch } from 'react';
import { AnyAction } from 'redux';

import { Card, HSpacing, VSpacing, VSpacingContainer } from '@hh.ru/magritte-ui';
import { BellOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { CompensationMode } from '@hh.ru/types-hh-microcore';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { CanvacBannerCase } from 'src/models/canvacVacancySalaryData';
import { PFP_LIQUIDITY_DEPRIORITIZATION_STATUSES } from 'src/models/pfpLiquidityWarning';

import AdviceBanner from 'src/components/VacancyImportantBanners/AdviceBanner';
import BrandedSnippetBanner from 'src/components/VacancyImportantBanners/BrandedSnippetBanner';
import CanvacBanner from 'src/components/VacancyImportantBanners/CanvacBanner';
import ConversionBanner from 'src/components/VacancyImportantBanners/ConversionBanner';

import styles from './vacancy-important-banners.less';

interface VacancyImportantBannersProps {
    dispatchModal: Dispatch<AnyAction>;
    marginBottom?: boolean;
}

const TrlKeys = {
    title: 'employer.vacancy.VacancyImportantBanners.header',
    hint: 'employer.vacancy.VacancyImportantBanners.header.tip',
};

const VacancyImportantBanners: TranslatedComponent<VacancyImportantBannersProps> = ({
    dispatchModal,
    marginBottom = false,
    trls,
}) => {
    const canvacVacancySalaryData = useSelector(({ canvacVacancySalaryData }) => canvacVacancySalaryData);
    const conversion = useSelector(({ vacancyAnalyticsConversion }) => vacancyAnalyticsConversion);
    const isBrandSnippetInfoAllowed = useSelector((store) => store.isBrandSnippetInfoAllowed);
    const advice = useSelector((state) => state.vacancyAdviceModalData.adviceWithStatistics);
    const vacancyView = useSelector((state) => state.vacancyView);
    const showPfpBanner = useSelector(({ pfpLiquidityWarning }) =>
        PFP_LIQUIDITY_DEPRIORITIZATION_STATUSES.includes(pfpLiquidityWarning?.status)
    );

    const shownConversion = conversion && !showPfpBanner;

    if (
        !canvacVacancySalaryData?.competitionLevel &&
        !advice.length &&
        !shownConversion &&
        !isBrandSnippetInfoAllowed
    ) {
        return null;
    }

    const { vacancyId = 0 } = vacancyView;

    return (
        <>
            <Card style="secondary" borderRadius={24} padding={12} stretched data-qa="vacancy-important-banners">
                <div className={styles.bannersHeader}>
                    <span className={styles.bannersHeaderText}>
                        <BellOutlinedSize16 initialColor="secondary" />
                        <HSpacing default={12} />
                        {trls[TrlKeys.title]}
                    </span>
                </div>
                <VSpacing default={10} />
                <VSpacingContainer default={8}>
                    {!!advice.length && <AdviceBanner dispatchModal={dispatchModal} />}
                    {shownConversion && <ConversionBanner vacancyId={vacancyId} />}
                    {/* temp_start-PORTFOLIO-37087 Канвак не умеет не в месячную зарплату */}
                    {(!vacancyView.compensation.mode || vacancyView.compensation.mode === CompensationMode.Month) && (
                        <CanvacBanner vacancyId={vacancyId} canvacBannerCase={CanvacBannerCase.Salary} />
                    )}
                    {/* temp_end-PORTFOLIO-37087 */}
                    <CanvacBanner vacancyId={vacancyId} canvacBannerCase={CanvacBannerCase.CompetitionLevel} />
                    <BrandedSnippetBanner />
                </VSpacingContainer>
            </Card>
            {marginBottom && <VSpacing default={12} />}
        </>
    );
};

export default translation(VacancyImportantBanners);
