import { SVGProps } from 'react';

const BettaIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="60" height="120" viewBox="0 0 60 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M14.3029 88.6733L14.3029 120.01L0.986267 120.01L0.986267 27.0767C0.986267 18.86 3.42293 12.4 8.2396 7.58335C12.9996 2.76668 19.6296 0.330017 27.7896 0.330017C35.6663 0.330017 41.7296 2.25668 46.2629 5.99668C50.7396 9.85001 52.9496 15.1767 52.9496 21.92C52.9496 26.5667 51.4763 30.93 48.5296 34.9533C45.583 38.9767 41.7863 41.7533 37.0829 43.2833L37.0829 43.51C44.1663 44.6433 49.6629 47.1933 53.4029 51.33C57.1429 55.3533 59.013 60.51 59.013 66.8567C59.013 74.3367 56.4063 80.4566 51.2496 85.16C46.0363 89.8633 39.2363 92.1866 30.7363 92.1866C24.7296 92.1866 19.2329 90.9967 14.3029 88.6733ZM22.7463 49.9133L22.7463 39.0333C27.6763 38.41 31.6996 36.5967 34.9296 33.5367C38.1029 30.42 39.6896 26.9633 39.6896 22.9967C39.6896 15.1767 35.6663 11.21 27.7329 11.21C23.4263 11.21 20.0829 12.57 17.7596 15.3467C15.4363 18.1233 14.3029 21.9767 14.3029 27.02L14.3029 76.83C19.4596 79.8333 24.5029 81.3067 29.3763 81.3067C34.1363 81.3067 37.8763 80.06 40.5396 77.6233C43.2029 75.13 44.5063 71.6167 44.5063 67.14C44.5063 56.9967 37.2529 51.33 22.7463 49.9133Z"
                fill="#768694"
            />
        </svg>
    );
};

export default BettaIcon;
