import { FC, PropsWithChildren } from 'react';

import { Action, BottomSheet, Modal, NavigationBar, VSpacing } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import ModalFooter from 'src/components/AIAssistant/components/ModalHrSettings/ModalFooter';
import ModalOptions from 'src/components/AIAssistant/components/ModalHrSettings/ModalOptions';
import ModalTitle from 'src/components/AIAssistant/components/ModalHrSettings/ModalTitle';

interface ModalInternalProps extends PropsWithChildren {
    isOpen: boolean;
    formId: string;
    onClose: () => void;
}

const ModalHrSettings: FC<ModalInternalProps> = (props) => {
    const { isOpen, formId, onClose, children } = props;

    return (
        <>
            <Modal
                title={<ModalTitle />}
                visible={isOpen}
                closeByClickOutside={false}
                data-qa="hr-ai-assistant__modal"
                options={<ModalOptions />}
                actions={
                    <Action
                        mode="secondary"
                        data-qa="hr-ai-assistant__modal-button-close"
                        icon={CrossOutlinedSize24}
                        onClick={onClose}
                    />
                }
                footer={<ModalFooter formId={formId} />}
            >
                {children}
            </Modal>
            <BottomSheet
                visible={isOpen}
                onClose={onClose}
                header={
                    <NavigationBar
                        size="standard"
                        title={<ModalTitle />}
                        right={
                            <Action
                                mode="secondary"
                                data-qa="hr-ai-assistant__modal-button-close"
                                icon={CrossOutlinedSize24}
                                onClick={onClose}
                            />
                        }
                    />
                }
                footer={<ModalFooter formId={formId} />}
            >
                {children}
                <VSpacing default={16} />
            </BottomSheet>
        </>
    );
};

export default ModalHrSettings;
