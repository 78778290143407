import { FC, useCallback } from 'react';

import { UturnLeftOutlinedSize24, TrashOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { BasicTriggerProps, BasicTriggerPropsWithoutTriggerName } from 'src/components/ControlsTrigger';
import VacanciesArchiveOrRestoreError from 'src/components/EmployerVacancies/Archived/ErrorNotification';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { ArchivationType } from 'src/models/archivedVacancies';
import fetcher from 'src/utils/fetcher';

import getMenuButtonIconProps from 'src/components/VacancyActions/getMenuButtonIconProps';

const ARCHIVED_VACANCIES_VISIBILITY_URL = '/employer/vacancies-visibility';
const ARCHIVED_VACANCIES_VISIBILITY_PERMISSION = 'ArchiveVacancy';

declare global {
    interface FetcherPutApi {
        [ARCHIVED_VACANCIES_VISIBILITY_URL]: {
            body: {
                archivationType: ArchivationType;
                ids: number[];
            };
            queryParams: void;
            response: void;
        };
    }
}

const TrlKeys = {
    deleteArchivedVacancy: 'employer.vacancyesArchive.deleteVacancy',
    restoreToArchive: 'employer.vacancyesArchive.backToArchive',
};

interface ArchivedVacanciesVisibilityActionProps extends BasicTriggerPropsWithoutTriggerName {
    Component: FC<BasicTriggerProps>;
    permissions: string[];
    onSuccessAction?: VoidFunction;
    archivationType: ArchivationType;
    archivedVacancies: number[];
    hideActionName?: boolean;
}

const ArchivedVacanciesVisibilityAction: TranslatedComponent<ArchivedVacanciesVisibilityActionProps> = ({
    Component,
    archivedVacancies,
    archivationType,
    permissions,
    triggerType,
    trls,
    hideActionName,
    onSuccessAction,
}) => {
    const { addNotification } = useNotification();

    const handleTrigger = useCallback(async () => {
        try {
            await fetcher.put(ARCHIVED_VACANCIES_VISIBILITY_URL, {
                archivationType,
                ids: archivedVacancies,
            });
            onSuccessAction?.();
        } catch (error) {
            console.error(error);
            addNotification(VacanciesArchiveOrRestoreError);
        }
    }, [addNotification, archivationType, archivedVacancies, onSuccessAction]);

    let triggerName;
    let actionName;
    let icon;
    if (archivationType === 'ARCHIVED') {
        triggerName = 'hide-archived-vacancies';
        actionName = trls[TrlKeys.deleteArchivedVacancy];
        icon = <TrashOutlinedSize24 initialColor="negative" />;
    } else if (archivationType === 'DELETED_BY_CLIENT') {
        triggerName = 'restore-archived-vacancies';
        actionName = trls[TrlKeys.restoreToArchive];
        icon = <UturnLeftOutlinedSize24 />;
    } else {
        return null;
    }

    const iconProps = getMenuButtonIconProps({
        triggerType,
        triggerName,
        icon,
    });

    return (
        <Component
            isPermitted={permissions.includes(ARCHIVED_VACANCIES_VISIBILITY_PERMISSION)}
            triggerName={triggerName}
            batchLength={archivedVacancies.length}
            triggerType={triggerType}
            onTrigger={handleTrigger}
            {...iconProps}
        >
            {hideActionName ? null : actionName}
        </Component>
    );
};

export default translation(ArchivedVacanciesVisibilityAction);
