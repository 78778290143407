import { useDispatch } from 'react-redux';

import { Button, useBreakpoint } from '@hh.ru/magritte-ui';
import { ArrowRightOutlinedSize16, MinusCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Notification from 'src/components/Notifications/Notification';
import { useNotification } from 'src/components/Notifications/Provider';
import fetchManagersList from 'src/components/VacancyActions/fetchManagersList';
import { TRANSFER_PERMISSION } from 'src/components/VacancyActions/permissions';
import Transfer from 'src/components/VacancyModals/Transfer';
import translation from 'src/components/translation';
import useOnOffState from 'src/hooks/useOnOffState';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    triggerText: 'employer.vacancy.managers.transferVacancy.modal.trigger.text',
    error: 'employer.service.message.fail',
};

const TransferVacancyAction: TranslatedComponent = ({ trls }) => {
    const dispatch = useDispatch();
    const { addNotification } = useNotification();
    const { isMobile } = useBreakpoint();
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const ownerId = useSelector((state) => state.vacancyView?.vacancyManagers?.owner?.id);
    const managerActiveCount = useSelector((state) => state.managerActiveCount?.count) || 0;
    const managersList = useSelector((state) => state.managersList);
    const permissions = useSelector((state) => state.permissions);
    const [isOpenModal, openModal, closeModal] = useOnOffState(false);
    const [isLoading, startLoading, finishLoading] = useOnOffState(false);
    const canTransfer = permissions.includes(TRANSFER_PERMISSION) && managerActiveCount > 0;

    if (!canTransfer || !vacancyId || !ownerId) {
        return null;
    }

    const handleOpenModal = async () => {
        if (managersList.length > 0) {
            return openModal();
        }

        startLoading();

        try {
            await dispatch(
                fetchManagersList({
                    params: { excludedManagerId: Number(ownerId), employerManagerId: 0, filterCanBeVacancyOwner: true },
                })
            );
        } catch (_) {
            addNotification(Notification, {
                props: {
                    addon: <MinusCircleFilledSize24 initialColor="negative" />,
                    autohideTime: 3000,
                    children: trls[TrlKeys.error],
                },
            });
            return undefined;
        } finally {
            finishLoading();
        }

        return openModal();
    };

    return (
        <>
            <Button
                mode="tertiary"
                size="small"
                icon={<ArrowRightOutlinedSize16 />}
                onClick={handleOpenModal}
                loading={isLoading}
                hideLabel={isMobile}
                aria-label={isMobile ? trls[TrlKeys.triggerText] : ''}
            >
                {trls[TrlKeys.triggerText]}
            </Button>
            {isOpenModal && (
                <Transfer
                    isVisible={isOpenModal}
                    vacanciesIds={[vacancyId]}
                    handleCloseModal={closeModal}
                    page="vacancy"
                />
            )}
        </>
    );
};

export default translation(TransferVacancyAction);
