import { Banner, Card, Text, VSpacing } from '@hh.ru/magritte-ui';
import {
    ChevronRightOutlinedSize16,
    CrossCircleOutlinedSize16,
    ExclamationTriangleOutlinedSize16,
} from '@hh.ru/magritte-ui/icon';
import { usePush } from '@hh.ru/redux-spa-middleware';
import Conversion from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import routePaths from 'src/app/routePaths';
import PfpLiquidityVacanciesTopModal from 'src/components/PfpLiquidityVacanciesTopModal';
import ConversionBanner from 'src/components/VacancyImportantBanners/ConversionBanner';
import translation from 'src/components/translation';
import useOnOffState from 'src/hooks/useOnOffState';
import { useSelector } from 'src/hooks/useSelector';
import { PfpLiquidityDeprioritizationStatus, PfpLiquidityStatus } from 'src/models/pfpLiquidityWarning';

import styles from './style.less';

const TrlKeys = {
    title: {
        [PfpLiquidityStatus.VacancyDeprioritized]: 'employer.vacancy.pfpLiquidityBanner.vacancyDeprioritized.title',
        [PfpLiquidityStatus.ManagerDeprioritized]: 'employer.vacancy.pfpLiquidityBanner.vacancyDeprioritized.title',
        [PfpLiquidityStatus.ManagerVacanciesHidden]: 'employer.vacancy.pfpLiquidityBanner.managerVacanciesHidden.title',
    },
    titleToCorrect: {
        [PfpLiquidityStatus.VacancyDeprioritized]:
            'employer.vacancy.pfpLiquidityBanner.vacancyDeprioritized.titleToCorrect',
        [PfpLiquidityStatus.ManagerDeprioritized]:
            'employer.vacancy.pfpLiquidityBanner.vacancyDeprioritized.titleToCorrect',
        [PfpLiquidityStatus.ManagerVacanciesHidden]:
            'employer.vacancy.pfpLiquidityBanner.managerVacanciesHidden.titleToCorrect',
    },
    actionToCorrect: {
        [PfpLiquidityStatus.VacancyDeprioritized]:
            'employer.vacancy.pfpLiquidityBanner.vacancyDeprioritized.actionToCorrect',
        [PfpLiquidityStatus.ManagerDeprioritized]:
            'employer.vacancy.pfpLiquidityBanner.managerDeprioritized.actionToCorrect',
        [PfpLiquidityStatus.ManagerVacanciesHidden]:
            'employer.vacancy.pfpLiquidityBanner.managerVacanciesHidden.actionToCorrect',
    },
    contactsOne: 'contact.1',
    contactsSome: 'contact.2',
    contactsMany: 'contact.5',
};

const PfpLiquidityBanner: TranslatedComponent = ({ trls }) => {
    const status = useSelector(
        ({ pfpLiquidityWarning }) => pfpLiquidityWarning?.status as PfpLiquidityDeprioritizationStatus
    );
    const vacancyId = useSelector((state) => String(state.vacancyView.vacancyId));
    const push = usePush();

    const responsesToBuy = useSelector(({ pfpLiquidityWarning }) => Number(pfpLiquidityWarning?.responsesToBuy));
    const [visible, setVisible, setClose] = useOnOffState(false);
    const conversion = useSelector(({ vacancyAnalyticsConversion }) => vacancyAnalyticsConversion);

    const textStyle = status === PfpLiquidityStatus.ManagerVacanciesHidden ? 'negative' : 'warning';
    const cardStyle = status === PfpLiquidityStatus.ManagerVacanciesHidden ? 'negative-secondary' : 'warning-secondary';
    const Icon =
        status === PfpLiquidityStatus.ManagerVacanciesHidden
            ? CrossCircleOutlinedSize16
            : ExclamationTriangleOutlinedSize16;

    const handleSubBannerClick = () => {
        if (status === PfpLiquidityStatus.VacancyDeprioritized) {
            push(`${routePaths.vacancyResponses.replace('(.mvc)?', '')}?vacancyId=${vacancyId}`);
            return;
        }
        if (
            status === PfpLiquidityStatus.ManagerDeprioritized ||
            status === PfpLiquidityStatus.ManagerVacanciesHidden
        ) {
            setVisible();
        }
    };

    return (
        <>
            <Banner
                content={
                    <>
                        <Text style={textStyle}>
                            <div className={styles.pfpLiquidityBannerTitle}>
                                <Icon initialColor={textStyle} />
                                {trls[TrlKeys.title[status]]}
                            </div>
                        </Text>
                        <VSpacing default={24} />
                        <Card stretched borderRadius={12} padding={12} onClick={handleSubBannerClick}>
                            <div className={styles.pfpLiquidityBannerSubtitle}>
                                <Text typography="label-3-regular" style="secondary">
                                    {trls[TrlKeys.titleToCorrect[status]]}
                                </Text>
                                <ChevronRightOutlinedSize16 initialColor="tertiary" />
                            </div>
                            <VSpacing default={4} />
                            <Text typography="label-3-regular">
                                {formatToReactComponent(trls[TrlKeys.actionToCorrect[status]], {
                                    '{0}': (
                                        <Conversion
                                            value={responsesToBuy}
                                            one={trls[TrlKeys.contactsOne]}
                                            some={trls[TrlKeys.contactsSome]}
                                            many={trls[TrlKeys.contactsMany]}
                                        />
                                    ),
                                })}
                            </Text>
                        </Card>
                        {!!conversion && (
                            <>
                                <VSpacing default={12} />
                                <ConversionBanner vacancyId={Number(vacancyId)} />
                            </>
                        )}
                    </>
                }
                style={cardStyle}
                stretched
                showClose={false}
            />
            <PfpLiquidityVacanciesTopModal visible={visible} onClose={setClose} />
        </>
    );
};

export default translation(PfpLiquidityBanner);
