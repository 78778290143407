import { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import urlParser from 'bloko/common/urlParser';

import submitPostData from 'Utils/SubmitPostData';
import IFrame from 'src/components/IFrame';
import usePreparedVacancy from 'src/components/VacancyCreate/hooks/usePreparedVacancy';

export const IFRAME_NAME = 'previewFrame';

const getDraftPreviewUrl = (parsedUrl, draftId, showHidden) =>
    `/vacancy/preview_draft?${urlParser.stringify({
        ...parsedUrl.params,
        draftId,
        doPreview: 'true',
        showHidden: showHidden || null,
    })}`;

const getFormPreviewUrl = (parsedUrl, toolsVisible) =>
    `/vacancy/preview?${urlParser.stringify({ ...parsedUrl.params, doPreview: 'true', toolsVisible })}`;

const GAP = 5;

const VacancyPreview = ({ draftId, formValues, currentUrl, visible, toolsVisible = false, showHidden, autoWidth }) => {
    const [spinnerVisible, setSpinnerVisible] = useState(true);
    const prepareVacancy = usePreparedVacancy(true);

    const iFrameRef = useRef();
    const resizeObserverRef = useRef();

    useEffect(() => {
        return () => {
            resizeObserverRef.current?.disconnect();
        };
    }, []);

    useEffect(() => {
        if (!visible || !draftId || formValues) {
            return;
        }

        submitPostData({
            url: getDraftPreviewUrl(urlParser(currentUrl), draftId, showHidden),
            target: IFRAME_NAME,
            data: {},
        });
    }, [visible, draftId, currentUrl, formValues, showHidden]);

    useEffect(() => {
        if (!visible || !formValues) {
            return;
        }

        const preparedValues = prepareVacancy(formValues);
        const vacancyJSONBody = JSON.stringify(preparedValues);

        submitPostData({
            url: getFormPreviewUrl(urlParser(currentUrl), toolsVisible),
            target: IFRAME_NAME,
            data: { vacancyJSONBody },
        });
    }, [visible, formValues, prepareVacancy, currentUrl, toolsVisible]);

    const hideSpinner = () => {
        setSpinnerVisible(false);
    };

    const handleIFrameLoad = () => {
        if (iFrameRef?.current?.contentWindow) {
            resizeObserverRef.current = new ResizeObserver((entries) => {
                entries.forEach((entry) => {
                    if (iFrameRef.current) {
                        let iFrameHeight = entry.target.scrollHeight;

                        if (autoWidth) {
                            iFrameHeight += GAP;
                        }

                        iFrameRef.current.style.height = `${iFrameHeight}px`;
                    }
                });
            });
            resizeObserverRef.current.observe(iFrameRef.current.contentWindow.document.body);
        }

        hideSpinner();
    };

    return (
        <div className={classnames('vacancy-preview-popup', { 'vacancy-preview-popup_auto-width': autoWidth })}>
            {spinnerVisible && <span className="prosper-spinner prosper-spinner_big prosper-spinner_centered" />}
            <IFrame
                ref={iFrameRef}
                onLoad={handleIFrameLoad}
                className={classnames('vacancy-preview-iframe', { 'vacancy-preview-iframe_auto-width': autoWidth })}
                data-qa="vacancycreate-preview-iframe"
                name={IFRAME_NAME}
                scrolling="no"
            />
        </div>
    );
};

export default connect(
    ({
        router: {
            location: { pathname, search },
        },
    }) => ({
        currentUrl: `${pathname}${search}`,
    })
)(VacancyPreview);
