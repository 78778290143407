import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useSelectorNonNullable } from 'src/hooks/useSelector';

const TrlKeys = {
    warningTitle: 'auction.banner.edit.hover.warningTitle',
    warningDescription: 'auction.banner.edit.hover.warningDescription',
};

const HoverWarningTipToEdit: TranslatedComponent = ({ trls }) => {
    const title = useSelectorNonNullable(
        ({ auctionData }) => auctionData.currentVacancyAuctionCampaign?.campaignAccountInfo.title || ''
    );
    return (
        <div data-qa="tip-to-edit-warning">
            <div>
                {format(trls[TrlKeys.warningTitle], {
                    '{0}': title,
                })}
            </div>
            <VSpacing base={4} />
            <div>{trls[TrlKeys.warningDescription]}</div>
        </div>
    );
};

export default translation(HoverWarningTipToEdit);
