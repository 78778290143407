import { FC, PropsWithChildren } from 'react';

import FormVSpacing, { FormVSpacingType } from 'src/components/VacancyCreate/common/FormVSpacing';

interface FormItemProps extends PropsWithChildren {
    type?: 'card' | 'item';
}

const FormItem: FC<FormItemProps> = ({ children, type = 'item', ...props }) => {
    if (children) {
        return (
            <div {...props}>
                <FormVSpacing value={type === 'card' ? FormVSpacingType.FieldItemCard : FormVSpacingType.FieldItem} />
                {children}
            </div>
        );
    }
    return <FormVSpacing value={FormVSpacingType.FieldItem} />;
};

export default FormItem;
