import { FC } from 'react';

import { Link, Text, VSpacing } from '@hh.ru/magritte-ui';
import { ArrowDiagonalRightUpOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import Column, { ColumnsRow } from 'bloko/blocks/column';

import {
    AIAssistantHRDescription,
    AIAssistantHrSettingsModal,
    useAIAssistantHrSettingsModal,
    constantsAIAssistant,
} from 'src/components/AIAssistant';

import CardControl from 'src/pages/VacancyView/components/VacancyAIAssistantTab/components/TabPane/components/CardControl';

const TabPane: FC = () => {
    const { isModalOpen, openModal, closeModal } = useAIAssistantHrSettingsModal(false);

    const handleEnableAIAssistantClick = () => {};
    const handleSetUpAIAssistantClick = () => {
        openModal();
    };

    const handleSubmit = () => {};

    return (
        <ColumnsRow>
            <Column xs="4" s="8" m="8" l="10">
                <CardControl onEnableClick={handleEnableAIAssistantClick} onSetUpClick={handleSetUpAIAssistantClick} />
                <VSpacing default={24} />
                <Text typography="title-4-semibold" data-qa="hr-ai-assistant__description-title">
                    Доверьте поиск кандидатов и общение с ними AI&#8209;Ассистенту
                </Text>
                <VSpacing default={20} />
                <AIAssistantHRDescription />
                <VSpacing default={20} />
                <Link
                    href={constantsAIAssistant.URL_HR_AI_ASSISTANT_DOCS}
                    mode="primary"
                    style="accent"
                    typography="label-3-regular"
                    data-qa="hr-ai-assistant__link-docs"
                    iconRight={<ArrowDiagonalRightUpOutlinedSize16 />}
                >
                    Как работает AI-ассистент
                </Link>
            </Column>
            <AIAssistantHrSettingsModal isOpen={isModalOpen} onClose={closeModal} onSubmit={handleSubmit} />
        </ColumnsRow>
    );
};

export default TabPane;
