import { Text, VSpacing, Button, Card } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import routePaths from 'src/app/routePaths';
import FlexibleRow, { FlexibleRowAlignItems } from 'src/components/FlexibleRow';
import translation from 'src/components/translation';
import { TopResponsesVacancies } from 'src/models/pfpLiquidityWarning';

import styles from './styles.less';

const TrlKeys = {
    toResponses: 'vacancies.employerPfpLiquidityModal.toResponses',
};

interface VacanciesListProps {
    vacancies: Array<TopResponsesVacancies>;
}

const VacanciesList: TranslatedComponent<VacanciesListProps> = ({ vacancies, trls }) => {
    return (
        <div className={styles.vacanciesListWrapper}>
            {vacancies.map(({ id, name, areaName }) => (
                <Card key={id} borderWidth="default" padding={24} borderRadius={24} stretched>
                    <FlexibleRow alignItems={FlexibleRowAlignItems.Center}>
                        <div className={styles.vacanciesListItemLeft}>
                            <Text typography="subtitle-1-semibold">
                                <div className={styles.overflowText}>{name}</div>
                            </Text>

                            <VSpacing default={6} />
                            <Text typography="label-3-regular" style="secondary">
                                {areaName}
                            </Text>
                        </div>
                        <Button
                            Element={SPALink}
                            to={`${routePaths.vacancyResponses.replace('(.mvc)?', '')}?vacancyId=${id}`}
                            target="_blank"
                            mode="secondary"
                            style="accent"
                        >
                            {trls[TrlKeys.toResponses]}
                        </Button>
                    </FlexibleRow>
                </Card>
            ))}
        </div>
    );
};

export default translation(VacanciesList);
